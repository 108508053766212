<template>
  <div
    class="target-small-image-campaign"
    :class="{
      'target-small-image-campaign--default': themes.Default === theme,
      'target-small-image-campaign--blue': themes.Blue === theme,
      'target-small-image-campaign--green': themes.Green === theme,
      'target-small-image-campaign--grey': themes.Grey === theme,
      'target-small-image-campaign--black': themes.Black === theme,
      'target-small-image-campaign--white': themes.White === theme,
      'target-small-image-campaign--lilly': themes.Lilly === theme,
      'target-small-image-campaign--secondary-pink': themes.SecondaryPink === theme,
      'target-small-image-campaign--tertiary-content-green': themes.TertiaryContentGreen === theme,
      'target-small-image-campaign--tertiary-marketplace-green':
        themes.TertiaryMarketplaceGreen === theme,
    }"
    @click="handleTargetBannerClick"
  >
    <div
      class="target-small-image-campaign__wrapper"
      :is="isExternalLink ? 'a' : link ? 'router-link' : 'div'"
      :to="isExternalLink ? '' : link"
      :href="isExternalLink ? link : ''"
      :target="isExternalLink ? '_blank' : ''"
    >
      <div
        class="target-small-image-campaign__image-wrapper"
        :class="{
          'target-small-image-campaign--contain-image': containImage,
        }"
        :style="{ backgroundColor }"
        v-if="mboxData.image && !imageResponseEmpty"
      >
        <MediaHandler
          v-if="images"
          class="target-small-image-campaign__image"
          img-type="background"
          :hex="backgroundColor"
          :placeholder="images"
        />
      </div>
      <div class="target-small-image-campaign__content typeset">
        <h3 class="target-small-image-campaign__title">{{ title }}</h3>
        <p class="target-small-image-campaign__description">{{ description }}</p>
        <VButton
          class="target-small-image-campaign__cta"
          :type="buttonVariant"
          v-if="cta && link"
          >{{ cta }}</VButton
        >
      </div>
      <div v-if="sponsored" class="target-small-image-campaign__sponsored-label">Sponsored</div>
    </div>
  </div>
</template>
<style lang="scss" src="./target-small-image-campaign.scss" scoped></style>

<script setup lang="ts">
import { UmbracoApi } from '@/src/core/api';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { Req } from '@/src/core/services/requester';
import { IAdobeTargetOffer, ITargetSmallImageCampaign } from '@/src/core/types/adobe';
import { ISources } from '@/src/core/types/interfaces';
import { CampaignColorThemes } from '@/src/core/types/ui';
import { computed, ref, watch } from 'vue';

interface TargetSmallImageCampaignProps {
  offer: IAdobeTargetOffer<ITargetSmallImageCampaign>;
  targetId?: string;
}

const props = defineProps<TargetSmallImageCampaignProps>();
const themes = CampaignColorThemes;
const images = ref<ISources[] | null>(null);
const imageResponseEmpty = ref<boolean>(false);

const mboxData = computed(() => {
  const content = props.offer.content && props.offer.content[0];
  return content?.content;
});

const title = computed(() => mboxData.value.title);
const description = computed(() => mboxData.value.description);
const image = computed(() => mboxData.value.image);
const theme = computed(() => mboxData.value.theme || themes.Default);
const link = computed(() => mboxData.value.link);
const backgroundColor = computed(() => mboxData.value.imageBackgroundColor || 'transparent');
const containImage = computed(() => mboxData.value.containImage);
const isLinkTargetBlank = computed(() => mboxData.value.linkTargetBlank);
const isExternalLink = computed(
  () => isLinkTargetBlank.value && (link.value?.includes('http') || link.value?.includes('https')),
);
const cta = computed(() => mboxData.value.cta);
const sponsored = computed(() => mboxData.value.sponsored);
const buttonVariant = computed(() => {
  switch (theme.value) {
    case CampaignColorThemes.Black:
      return UiVariant.Secondary;
    default:
      return UiVariant.Default;
  }
});

const fetchImageSources = async () => {
  const { IsSuccess, Data } = await Req({
    url: UmbracoApi.getImageSources,
    method: 'POST',
    data: {
      id: mboxData.value.image,
      widths: [400, 800],
    },
  });

  if (!Data) {
    imageResponseEmpty.value = true;
  }

  if (IsSuccess && Data) {
    images.value = [];
    images.value.push(...Data.images);
  }
};

watch(
  image,
  (newValue: string, oldValue: string) => {
    if (newValue !== oldValue) {
      fetchImageSources();
    }
  },
  { immediate: true },
);

const handleTargetBannerClick = () => {
  AdobeLaunchTracking.interaction({
    action: 'click',
    label: '3rd Party Banner',
    component: props.offer.content[0].component || 'Not set',
    location: props.targetId || 'Not set',
    value: '',
  });
};
</script>

<template>
  <div class="product-details-seller__logo-wrapper" v-ui-test="'product-details-supplier-logo'">
    <span class="product-details-seller__logo-title">{{
      textK('UI_PRODUCT_SOLD_HANDLED_BY')
    }}</span>
    <img v-if="logo.url" class="product-details-seller__logo" :src="logo.url" :alt="logo.alt" />
    <h3 v-else class="product-details-seller__title">{{ logo.alt || 'Supplier logo' }}</h3>
  </div>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';

interface Props {
  logo: { url: string; alt: string };
}

defineProps<Props>();

const textK = useText();
</script>

import router from '@/src/core/router';
import {
  DownloadDetails,
  IAdobeUserInfo,
  QuoteTracking,
  SearchFilterData,
  SearchInformation,
  TrackableOrder,
} from '@/src/core/types/adobe';
import { CartEntry, OrderReceipt, Product, QuoteDetail } from '@/src/core/types/api';
import { ICart, RouteConfigMeta } from '@/src/core/types/interfaces';
import { UserInfo } from '@/src/core/types/userInfo';
import { AddressService } from '@/src/market/services/address';
import { hasBasePrice } from '@/src/market/services/airbus-parts';
import { isDefaultForwarder } from '@/src/market/services/carrier-service';
import { isAirbusProduct } from '@/src/market/services/product-parts';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { RouteLocationNormalized, RouteMeta } from 'vue-router';
import { useAuthenticationStore } from '../stores/authentication';
import { IAdobeTargetInteraction } from '../types/adobe';
import {
  IN_STOCK,
  LOGGED_IN,
  NOT_APPLICABLE,
  NOT_AVAILABLE,
  NOT_LOGGED_IN,
  NO_PRICE,
  PRICED,
  TrackingCache,
  airbusLeadTime,
  defaultValueSetter,
  determineLeadTime,
  getCartLeadTime,
  getPageInfo,
  getProductLeadTime,
  getSku,
  getTimeToConversion,
  getUserCurrentAccount,
  isAuthenticated,
  isFromCart,
  routeIncludesPath,
} from './tracking-utils-service';

declare global {
  interface Window {
    adobeDataLayer: object[];
    digitalData: object[];
    digitalDataUserStore: UserInfo;
  }
}
export class AdobeLaunchTracking {
  private static adobeDatalayer = (window.adobeDataLayer = window.adobeDataLayer || []);

  public static unitType: string;
  public static hasTrackingHeader: boolean = false;
  public static eventCounter = 0;

  public static applyTrackingHeader(data: string) {
    const responseTracking = JSON.parse(data);
    if (!data) {
      return;
    }
    if (responseTracking.apply) {
      this.hasTrackingHeader = true;
      this.unitType = responseTracking.user?.unitType;
    }
  }

  /**
   * Fetch the user data from the data user store
   * @returns user info
   */
  private static getUserDigitalData(isReloaded?: boolean) {
    const authenticationStore = useAuthenticationStore();
    const userStore = useUserStore();
    const currentUser = userStore.currentUser;
    if (!currentUser || userStore.isBusy) {
      return;
    }
    const unitType = authenticationStore.userUnitType;
    const userUnitType = isAuthenticated() ? unitType : 'Not set';
    const accountNumber = currentUser?.DefaultUnitUid;
    const formattedActivationDate = currentUser?.ActivationDate
      ? dayjs(new Date(currentUser?.ActivationDate)).format('DD-MM-YYYY')
      : 'Not set';
    const directorAssigned = userStore.redB2BUnit
      ? userStore.redB2BUnit?.DirectorAssigned
      : userStore.blueB2BUnit?.DirectorAssigned || 'Not set';
    const region = userStore.redB2BUnit
      ? userStore.redB2BUnit?.b2BUnitSalesOrganization?.Code
      : userStore.blueB2BUnit?.b2BUnitSalesOrganization?.Code;
    const category = userStore.redB2BUnit
      ? `${userStore.redB2BUnit?.CompanyCategory?.Code} ${userStore.redB2BUnit?.CompanyCategory?.Name}`
      : `${userStore.blueB2BUnit?.CompanyCategory?.Code} ${userStore.blueB2BUnit?.CompanyCategory?.Name}`;
    const district = userStore.redB2BUnit
      ? userStore.redB2BUnit?.District.Name
      : userStore.blueB2BUnit?.District.Name;

    let userInfo: Partial<IAdobeUserInfo> = {};

    if (!userStore.isBusy && (isReloaded || currentUser)) {
      userInfo = {
        customerName: userStore?.company?.Name,
        asm: isAuthenticated() ? String(currentUser?.IsAsm) : 'Not set',
        globalId: currentUser?.GlobalId,
        hashedEmail: isAuthenticated() ? currentUser?.HashedEmail : 'Not set',
        loginStatus: isAuthenticated() ? LOGGED_IN : NOT_LOGGED_IN,
        accountNumber,
        redAccountNumber: getUserCurrentAccount().RedChildUnit?.Uid || '',
        blueAccountNumber: getUserCurrentAccount().BlueChildUnit?.Uid || '',
        accountNumberTruncated: accountNumber && accountNumber.slice(4),
        lastLoginDays: currentUser?.LastLoginNoDays,
        activationDate: formattedActivationDate,
        district,
        region,
        directorAssigned,
        category,
        userType: userUnitType,
      };
    }

    const withDefaultValues = defaultValueSetter(userInfo);
    return withDefaultValues;
  }

  /**
   * Track page view
   * @param routeTo - target location being navigated to
   * @param routeFrom - target location being navigated away from
   * @returns early return handling when route has finance and no query parameter.
   * This ensures that the event does not occur twice on the finance route.
   * Handle the product route to avoid triggering the event within the page view.
   */
  public static pageView(
    routeTo: RouteLocationNormalized,
    routeFrom: RouteLocationNormalized,
    meta: RouteConfigMeta | RouteMeta | null,
    isReloaded: boolean = false,
  ) {
    const data = {
      event: 'pageView',
      userInfo: this.getUserDigitalData(isReloaded),
      pageInfo: getPageInfo(routeTo, routeFrom, meta!),
    };
    if (
      (routeIncludesPath(routeTo, 'finance') && Object.keys(routeTo.query).length === 0) ||
      routeIncludesPath(routeTo, 'sale')
    ) {
      return;
    }
    this.adobeDatalayer.push(data);
  }

  /**
   * Track page view
   * @param event - one of the three types of video tracking events
   * @param video - name and percentage watched of the video
   */
  public static trackVideo(
    event: 'videoPaused' | 'videoStarted' | 'videoFinished',
    video: { videoName: string; videoPercentage: string },
  ) {
    const data = {
      event,
      userInfo: this.getUserDigitalData(),
      pageInfo: getPageInfo(router.currentRoute.value),
      video,
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Track login event
   * @param route - route of vue-router
   */
  public static trackLogin(route: RouteLocationNormalized) {
    this.adobeDatalayer.push({
      event: 'login',
      userInfo: this.getUserDigitalData(),
      pageInfo: getPageInfo(route),
    });
  }

  /**
   * Track product view
   * @param productData - the product data
   */
  public static productView(productData: Product) {
    if (!productData) {
      return;
    }
    const data = {
      event: 'productView',
      userInfo: this.getUserDigitalData(),
      pageInfo: getPageInfo(router.currentRoute.value),
      products: {
        items: [this.getProductData(productData)],
      },
    };

    const withDefaultValues = defaultValueSetter(data);
    this.adobeDatalayer.push(withDefaultValues);
  }

  /**
   * Track add to cart
   * @param product - the product data
   */
  public static addToCart(product: Product) {
    const data = {
      event: 'add_to_cart',
      products: {
        items: [this.getProductData(product)],
      },
    };
    const withDefaultValues = defaultValueSetter(data);
    this.adobeDatalayer.push(withDefaultValues);
  }

  /**
   * Track cart quantity increase or decrease
   * @param product - the product data
   * @param oldQuantity - amount of products in the cart before quantity changed
   * @param newQuantity - amount of products in the cart after quantity changed
   * @param eventName - event name
   */
  public static cartVarying(
    product: CartEntry,
    oldQuantity: number,
    newQuantity: number,
    eventName: string,
  ) {
    if (!product) {
      return;
    }
    const data = {
      event: eventName,
      quantityInfo: {
        quantityFrom: oldQuantity,
        quantityTo: newQuantity,
      },
      products: {
        items: [this.getCartEntry(product, eventName, newQuantity)],
      },
    };
    const withDefaultValues = defaultValueSetter(data);
    this.adobeDatalayer.push(withDefaultValues);
  }

  /**
   * Remove an item to cart
   * @param product - the product data
   */
  public static removeFromCart(product: CartEntry) {
    const data = {
      event: 'remove_from_cart',
      products: {
        items: [this.getCartEntry(product)],
      },
    };
    const withDefaultValues = defaultValueSetter(data);
    this.adobeDatalayer.push(withDefaultValues);
  }

  /**
   * Clear cart entries
   * @param entries - cart entry data
   */
  public static clearCart(entries: CartEntry[]) {
    const data = {
      event: 'clear_cart',
      products: {
        items: entries.map((values) => this.getCartEntry(values)),
      },
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Add list of product to cart
   * @param entries - cart entry data
   */
  public static addListToCart(entries: CartEntry[]) {
    const data = {
      event: 'add_to_cart',
      products: {
        items: entries.map((values) => this.getCartEntry(values)),
      },
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Track cart view
   * @param entries - cart entry data
   */
  public static cartView(entries: CartEntry[]) {
    const data = {
      event: 'cart_view',
      products: {
        items: entries.map((values) => this.getCartEntry(values)),
      },
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Receives checkout cart and sellers and return trackable orders
   * @param {OrderReceipt} orders
   * @param {ICart | QuoteDetail} cart
   * @returns trackable orders object
   */
  public static trackableOrders(orders: OrderReceipt, cart: ICart | QuoteDetail): TrackableOrder[] {
    return orders.OrderResults.map((order) => ({
      ...cart,
      orderInfo: {
        orderId: order.Order.Code,
        revenue: order.Order.TotalPrice.Value,
        uniqueProductsCount: order.Order.Entries.length,
        orderPriority: order.Order.PriorityInfo.Priority.Name,
        deliveryDate: dayjs(order.Order.ConfirmedDeliveryDate).format('MM-DD-YYYY'),
        forwarder: isDefaultForwarder(order.Order.Carrier) ? 'default' : 'custom forwarder',
        shipToAddress: AddressService.isDefault(order.Order.DeliveryAddress)
          ? 'default'
          : 'custom address',
      },
      products: {
        items: order.Order.Entries.map((values) =>
          this.getCartEntry(values, undefined, undefined, order.Order.AirbusProcurementType),
        ),
      },
    })) as {} as TrackableOrder[];
  }

  /**
   * Track interaction event
   * @param {Interaction} interactionDetails
   * @returns early return if type of cart.Guid is undefined
   */
  public static interaction(interactionDetails: IAdobeTargetInteraction) {
    const data = {
      event: 'interaction',
      userInfo: this.getUserDigitalData(),
      interaction: { ...interactionDetails },
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Track purchase event
   * @param {TrackableOrder} trackableOrder
   * @returns early return if type of cart.Guid is undefined
   */
  public static purchase(trackableOrder: TrackableOrder) {
    if (!isFromCart(trackableOrder)) {
      return;
    }
    const data = {
      event: 'purchase',
      pageInfo: getPageInfo(router.currentRoute.value),
      userInfo: this.getUserDigitalData(),
      cart: {
        numberOfUniqueSeller: trackableOrder.SellerGroup.length,
        cartId: trackableOrder.Code,
        timeToConversion: getTimeToConversion(trackableOrder),
      },
      orderInfo: trackableOrder.orderInfo,
      products: trackableOrder.products,
    };

    this.adobeDatalayer.push(data);
  }

  private static getProductData(product: Product) {
    const productAdditionalInfoStore = useProductAdditionalInfoStore();
    const airbusPartStore = useAirbusPartStore();

    if (!product) {
      return;
    }
    let price;
    let packageType;
    let priceFlag;
    let additionalInfo;
    let leadTimeLocation;
    let procurementType;
    let condition;
    let batchNumber;
    if (!isAirbusProduct(product.OwningSystem)) {
      additionalInfo = productAdditionalInfoStore.additionalDataByOfferId(product.Id);
      price =
        additionalInfo?.BasePrice?.Value !== undefined
          ? Number(additionalInfo?.BasePrice?.Value)
          : NOT_AVAILABLE;
      packageType = additionalInfo?.PriceUnit?.SapCode ?? additionalInfo?.SalesUnit?.SapCode;
      leadTimeLocation = additionalInfo?.Warehouse?.Name;
      priceFlag = additionalInfo?.ProductRule?.Sellable !== 'YES' ? NO_PRICE : PRICED;
    }

    if (isAirbusProduct(product.OwningSystem)) {
      const airbusPart = airbusPartStore.airbusPartByOfferId(product.Id);
      if (airbusPart && airbusPart?.procurements?.length === 0) {
        price = NOT_AVAILABLE;
        priceFlag = NO_PRICE;
      } else {
        const type = TrackingCache.procurementType;
        const procurementResult = airbusPart?.procurements.filter(
          (values) => values.procurementType === type,
        );
        const procurement = procurementResult?.shift();
        price = hasBasePrice(procurement) ? Number(procurement?.basePrice?.value) : NOT_AVAILABLE;
        priceFlag = hasBasePrice(procurement) ? PRICED : NO_PRICE;
        leadTimeLocation = procurement?.warehouseLocationDescription;
        procurementType = router.currentRoute.value.params?.procurementType
          ? router.currentRoute.value.params?.procurementType
          : procurement?.procurementType;
        packageType = procurement?.priceUnit?.sapCode ?? procurement?.salesUnit?.sapCode;
        batchNumber = procurement?.batchNumber;
      }
    }

    const productData = {
      quantity: additionalInfo?.Quantity ?? product.AddToBasketQty,
      packageType,
      pnr: getSku(product.Id)?.split(':')[0],
      sku: product?.Sku ?? getSku(product.Id),
      cageCode: product?.ProductManufacturer?.CageCode,
      seller: additionalInfo?.Seller ?? product.SupplierDisplay,
      hasPhoto: String(product?.HasImage),
      name: product?.Name,
      price,
      priceFlag,
      leadTimeLocation,
      certificateType: product?.Certificate,
      manufacturer: product?.ProductManufacturer?.Name,
      ...(!isAirbusProduct(product.OwningSystem) && { condition }),
      ...(isAirbusProduct(product.OwningSystem) && { procurementType }),
      ...(isAirbusProduct(product?.OwningSystem) && { batchNumber }),
      leadTime: getProductLeadTime(
        product,
        airbusLeadTime(),
        productAdditionalInfoStore.additionalDataByOfferId(product.Id),
      ),
    };
    const withDefaultValues = defaultValueSetter(productData);

    return withDefaultValues;
  }

  /**
   * Product product information from cart entry
   * @param cartEntry - cart entry data
   * @returns - list of products
   */
  private static getCartEntry(
    cartEntry: CartEntry,
    eventName?: string,
    newQuantity?: number,
    procurementType?: string,
  ) {
    const airbusPartStore = useAirbusPartStore();

    let packageType;
    let price;
    let priceFlag;
    let seller;
    let leadTimeLocation;
    let condition;
    let batchNumber;
    let certificateType;
    if (!cartEntry) {
      return;
    }

    let quantity;
    if (eventName && (eventName === 'cart_qty_increased' || eventName === 'cart_qty_decreased')) {
      quantity = newQuantity;
    } else {
      quantity = Number(cartEntry?.Quantity);
    }
    if (isAirbusProduct(cartEntry.Product.OwningSystem)) {
      const airbusPart = airbusPartStore.airbusPartByOfferId(cartEntry.Product.Id);
      if (airbusPart && airbusPart?.procurements?.length === 0) {
        price = NOT_AVAILABLE;
        priceFlag = NO_PRICE;
      } else {
        const type = TrackingCache.procurementType;
        const procurementResult = airbusPart?.procurements.filter(
          (values) => values.procurementType === type,
        );
        const procurement = procurementResult?.shift();
        price = hasBasePrice(procurement) ? Number(procurement?.basePrice?.value) : NOT_AVAILABLE;
        priceFlag = airbusPart?.productRule?.sellable === 'YES' ? PRICED : NO_PRICE;
        leadTimeLocation = procurement?.warehouseLocationDescription;
        seller = airbusPart?.masterShopId;
        packageType = procurement?.priceUnit?.sapCode ?? procurement?.salesUnit?.sapCode;
        procurementType = router.currentRoute.value.params?.procurementType
          ? router.currentRoute.value.params?.procurementType
          : procurementType;
        batchNumber = procurement?.batchNumber;
        certificateType =
          cartEntry.Product.AirbusCertificateForSalesNew?.FormattedName || 'Not set';
      }
    } else {
      const state = cartEntry.Product?.State;
      price =
        cartEntry?.BasePrice?.Value !== undefined
          ? Number(cartEntry?.BasePrice?.Value)
          : NOT_AVAILABLE;
      packageType = cartEntry?.SalesUnit ?? cartEntry?.PriceUnit;
      priceFlag = cartEntry?.Product?.ProductRule?.Sellable !== 'YES' ? NO_PRICE : PRICED;
      seller = cartEntry.MasterShop?.Name ?? cartEntry?.Product?.SupplierDisplay;
      leadTimeLocation = cartEntry.Warehouse?.Name;
      condition = state;
    }

    const cartData = {
      quantity,
      packageType,
      pnr: cartEntry.Product.Sku.split(':')[0],
      sku: cartEntry.Product.Sku,
      cageCode: cartEntry.Product.ProductManufacturer?.CageCode,
      seller,
      leadTime: getCartLeadTime(cartEntry),
      hasPhoto: String(cartEntry.Product.HasImage),
      name: cartEntry.Product.Name,
      price,
      priceFlag,
      leadTimeLocation,
      certificateType: cartEntry.Product?.Certificate,
      manufacturer: cartEntry.Product.ProductManufacturer?.Name,
      ...(!isAirbusProduct(cartEntry.Product.OwningSystem) && { condition }),
      ...(isAirbusProduct(cartEntry.Product.OwningSystem) && { procurementType }),
      ...(isAirbusProduct(cartEntry.Product.OwningSystem) && { batchNumber }),
      ...(isAirbusProduct(cartEntry.Product.OwningSystem) && { certificateType }),
    };

    const withDefaultValues = defaultValueSetter(cartData);

    return withDefaultValues;
  }

  /**
   * Track search event
   * @param event - event name
   * @param {searchInformation} searchInfo
   * @param {Product[]} productsData
   */
  public static trackSearch(event: string, searchInfo: SearchInformation, productsData: Product[]) {
    let data = {
      event,
      pageInfo: getPageInfo(router.currentRoute.value),
      userInfo: this.getUserDigitalData(),
      searchInfo,
    };
    if (productsData && productsData.length > 0) {
      const mergedResult = Object.assign(data, {
        products: {
          items: productsData.map(this.getProductSearches),
        },
      });
      data = mergedResult;
    }

    const withDefaultValues = defaultValueSetter(data);
    this.adobeDatalayer.push(withDefaultValues);
  }

  /**
   * Receive product information from search
   * @param {Product} product
   * @returns product data
   */
  private static getProductSearches(product: Product) {
    const productAdditionalInfoStore = useProductAdditionalInfoStore();
    const airbusPartStore = useAirbusPartStore();

    if (!product) {
      return;
    }
    let price;
    let priceFlag;
    let leadTimeLocation;
    let condition;
    let procurementType;
    let batchNumber;
    if (isAirbusProduct(product?.OwningSystem)) {
      const airbusPart = airbusPartStore.airbusPartByOfferId(product.Id);
      if (airbusPart && airbusPart?.procurements?.length === 0) {
        price = NOT_AVAILABLE;
        priceFlag = NO_PRICE;
      } else {
        const type = TrackingCache.procurementType;
        const procurementResult =
          airbusPart &&
          airbusPart?.procurements?.filter((values) => values.procurementType === type);
        const procurement = procurementResult?.shift();
        price = hasBasePrice(procurement) ? Number(procurement?.basePrice?.value) : NOT_AVAILABLE;
        priceFlag = airbusPart?.productRule?.sellable === 'YES' ? PRICED : NO_PRICE;
        leadTimeLocation = procurement?.warehouseLocationDescription;
        procurementType = TrackingCache.procurementType;
        batchNumber = procurement?.batchNumber;
      }
    } else {
      const additionalInfo = productAdditionalInfoStore.additionalDataByOfferId(product.Id);
      const productRule = additionalInfo?.ProductRule?.Sellable;
      const priceInteger =
        additionalInfo?.BasePrice?.Value !== undefined
          ? Number(additionalInfo.BasePrice?.Value)
          : NOT_AVAILABLE;
      price = priceInteger ?? 0;
      priceFlag = productRule === 'YES' ? PRICED : NO_PRICE;
      leadTimeLocation = additionalInfo?.Warehouse?.Name;
      condition = product?.State;
    }
    const productData = {
      pnr: product.Sku.split(':')[0],
      sku: product.Sku,
      cageCode: product.ProductManufacturer?.CageCode,
      seller: product.SupplierDisplay,
      leadTime: AdobeLaunchTracking.productSearchAvailabilities(product),
      hasPhoto: String(product.HasImage),
      name: product.Name,
      price,
      priceFlag,
      leadTimeLocation,
      certificateType: product?.Certificate,
      manufacturer: product.ProductManufacturer?.Name,
      ...(isAirbusProduct(product.OwningSystem) && { procurementType }),
      ...(!isAirbusProduct(product.OwningSystem) && { condition }),
      ...(isAirbusProduct(product?.OwningSystem) && { batchNumber }),
    };

    const withDefaultValues = defaultValueSetter(productData);

    return withDefaultValues;
  }

  /**
   * Get availability lead time
   * @param {Product} product
   * @returns {string} Stock or Not in Stock
   */
  private static productSearchAvailabilities(product: Product): string | number {
    const productAdditionalInfoStore = useProductAdditionalInfoStore();

    const availabilityDate = productAdditionalInfoStore.additionalDataByOfferId(
      product.Id,
    )?.StockIndication;
    if (availabilityDate === 'N/A') {
      return NOT_APPLICABLE;
    }

    if (availabilityDate === 'In stock') {
      return IN_STOCK;
    }

    return determineLeadTime(availabilityDate!);
  }

  /**
   * Quote tracking
   * @param {QuoteTracking} QuoteTracking - cartEntries, product, quote
   */
  public static quoteTracking({ cartEntries = [], product, quote }: Partial<QuoteTracking>) {
    const isProduct = typeof product === 'object' && 'Id' in product && 'Code' in product;
    const data = {
      event: isProduct ? 'quote_created' : 'initiate_quote_checkout',
      userInfo: this.getUserDigitalData(),
      pageInfo: getPageInfo(router.currentRoute.value),
      products: {
        items: isProduct
          ? [this.getProductData(product)]
          : cartEntries.map((entry) => this.getCartEntry(entry)),
      },
      quote,
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Scroll tracking
   * @param route - target route location
   */
  public static scrollTracking(route: RouteLocationNormalized) {
    const data = {
      event: 'scroll100',
      pageInfo: getPageInfo(route),
    };

    this.adobeDatalayer.push(data);
  }

  /**
   * Track elements visible in viewport
   * @param {Product[]} products
   * @param {RouteLocationNormalized} route
   * @param {boolean} isInputChanged
   * @param {boolean} isComponentDestroyed
   */
  public static productImpressionTracking(
    products: Product[],
    route: RouteLocationNormalized,
    isInputChanged: boolean = false,
    isComponentDestroyed?: boolean,
  ) {
    if (isInputChanged || isComponentDestroyed) {
      this.eventCounter = 0;
    }

    if (this.eventCounter < 1) {
      const data = {
        event: 'productImpression',
        pageInfo: getPageInfo(route),
        userInfo: this.getUserDigitalData(),
        product: {
          items: products.map(this.getProductData),
        },
      };

      if (!isComponentDestroyed) {
        this.eventCounter += 1;
      }
      this.adobeDatalayer.push(data);
    }
  }

  /**
   * Track filter search
   * @param {Product[]} productsData
   * @param {SearchFilterData} searchFilterData
   */
  public static searchFilterTracking(productsData: Product[], searchFilterData: SearchFilterData) {
    const data = {
      event: 'filteredSearch',
      ...searchFilterData,
      userInfo: this.getUserDigitalData(),
      products: {
        items: productsData.map(this.getProductSearches),
      },
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Track pdf download
   * @param {DownloadDetails} downloadDetails
   */
  public static pdfDownloadTracking(downloadDetails: DownloadDetails) {
    const data = {
      event: 'download',
      userInfo: this.getUserDigitalData(),
      download: { ...downloadDetails },
    };
    this.adobeDatalayer.push(data);
  }

  /**
   * Bid Tracking
   * @param {string} quoteId
   * @param {Product} product
   */
  public static bidTracking(quoteId: string, product: Product) {
    const data = {
      event: 'bid',
      pageInfo: getPageInfo(router.currentRoute.value),
      userInfo: this.getUserDigitalData(),
      products: {
        items: [this.getProductData(product)],
      },
      quote: { id: quoteId },
    };
    this.adobeDatalayer.push(data);
  }

  public static productImpressionsTrackingOnSearch(products: Product[]) {
    if (!products) {
      return undefined;
    }
    const data = {
      event: 'productImpression',
      pageInfo: getPageInfo(router.currentRoute.value),
      userInfo: this.getUserDigitalData(),
      product: {
        items: products.map(this.getProductData),
      },
    };

    this.adobeDatalayer.push(data);
  }
}

<template>
  <div class="product-details-body-section">
    <ProductDetailsLeasePrice
      v-if="showLeasePrice"
      :lease-info="procurement?.leaseInfo"
      :product="product"
    />
    <VSimpleTable class="product-details-table" :headers="masterDataTableHeaders">
      <template v-for="data of product.MasterData" :key="data.Name">
        <template v-if="data.Name == 'Approved suppliers'">
          <product-details-approved-suppliers
            :product-id="product.Id"
            :owning-system="product.OwningSystem"
          ></product-details-approved-suppliers>
        </template>
        <template v-else-if="data.Value">
          <product-master-data
            :product-id="product.Id"
            :owning-system="product.OwningSystem"
            :master-data="data"
          >
          </product-master-data>
        </template>
      </template>
    </VSimpleTable>
    <VSimpleTable
      class="product-details-table"
      style="margin-top: 0"
      :headers="measurementTableHeaders"
    >
      <template v-for="data of product.Measurements" :key="data.Name">
        <template v-if="data.Value != null">
          <VSimpleTableRow :v-ui-test="data.TestTag">
            <VSimpleTableCell
              class="product-details-cell--index"
              :label="'cell-label'"
              :style="{ width: '50%' }"
              >{{ data.Name }}</VSimpleTableCell
            >
            <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }"
              >{{ data.Value }}
            </VSimpleTableCell>
          </VSimpleTableRow>
        </template>
      </template>
    </VSimpleTable>
    <product-details-certification
      :id="product.Id"
      :owning-system="product.OwningSystem"
      :has-certificate="product.HasCertificate"
      :certificate-type="product.Certificate"
    ></product-details-certification>
    <product-details-offers v-if="!isFhs" :id="product.Id"></product-details-offers>
    <template v-if="product.OfferData && product.OfferData.length > 0">
      <VSimpleTable class="product-details-table" :headers="['Offer details', '']">
        <VSimpleTableRow
          v-for="data of product.OfferData"
          :v-ui-test="data.TestTag"
          :key="data.Name"
        >
          <VSimpleTableCell
            class="product-details-cell--index"
            :label="'cell-label'"
            :style="{ width: '50%' }"
            >{{ data.Name }}</VSimpleTableCell
          >
          <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
            <template v-if="Array.isArray(data.Value)">
              <p v-for="item of data.Value" :key="item">{{ item }}</p>
            </template>
            <template v-else>{{ data.Value }}</template>
          </VSimpleTableCell>
        </VSimpleTableRow>
      </VSimpleTable>
    </template>

    <product-details-documents
      :id="product.Id"
      :owning-system="product.OwningSystem"
    ></product-details-documents>
    <template v-if="offer.DocumentsLinks.length > 0 || offer.DocumentsNames.length > 0">
      <product-details-attachments
        :document-links="offer.DocumentsLinks"
        :document-names="offer.DocumentsNames"
        :owning-system="product.OwningSystem"
      ></product-details-attachments>
    </template>
    <product-details-export-controls
      :export-controls="product.ExportControlData"
      :owning-system="product.OwningSystem"
    ></product-details-export-controls>

    <product-details-remarks
      :id="product.Id"
      :additional-data="productAdditionalData"
      :is-sale-used="isSaleUsed"
    ></product-details-remarks>
  </div>
</template>

<style scoped src="./product-details-body-section.scss" lang="scss"></style>

<script setup lang="ts">
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { TextKey } from '@/src/core/services/text-key';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { Product, ProductOffer } from '@/src/core/types/api';
import { AirbusPart } from '@/src/core/types/interfaces';

import ProductDetailsApprovedSuppliers from '@/src/market/components/product/product-details/product-details-approved-suppliers/product-details-approved-suppliers.vue';
import ProductDetailsAttachments from '@/src/market/components/product/product-details/product-details-attachments/product-details-attachments.vue';
import ProductDetailsCertification from '@/src/market/components/product/product-details/product-details-certification/product-details-certification.vue';
import ProductDetailsDocuments from '@/src/market/components/product/product-details/product-details-documents/product-details-documents.vue';
import ProductDetailsExportControls from '@/src/market/components/product/product-details/product-details-export-controls/product-details-export-controls.vue';
import ProductDetailsLeasePrice from '@/src/market/components/product/product-details/product-details-lease-price/product-details-lease-price.vue';
import ProductMasterData from '@/src/market/components/product/product-details/product-details-master-data/product-details-master-data.vue';
import ProductDetailsOffers from '@/src/market/components/product/product-details/product-details-offers/product-details-offers.vue';
import ProductDetailsRemarks from '@/src/market/components/product/product-details/product-details-remarks/product-details-remarks.vue';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { productAdditionalData as productAdditionalDataService } from '@/src/market/services/product-parts';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps<{ product: Product; offer: ProductOffer; isFhs?: boolean }>();
const route = useRoute();

const masterDataTableHeaders = computed(() => [TextKey('UI_PRODUCTPAGE_MASTERDATA'), '']);
const measurementTableHeaders = computed(() => [TextKey('UI_PRODUCTPAGE_MEASUREMENTS'), '']);

const productAdditionalData = computed(() => {
  if (props.product) {
    return productAdditionalDataService(props.product.Id, props.product.OwningSystem);
  }
  return null;
});

const procurement = computed(() => {
  const productData = (productAdditionalData.value as AirbusPart)?.procurements;

  if (!productData) {
    return undefined;
  }

  return getProcurementByRouteParams(route.params, productData);
});

const isTool = computed(() => props.product?.IsTool);

const isLeaseProcurement = computed(
  () => procurement.value?.procurementType === ProcurementType.Lease,
);

const isProprietaryPart = computed(() => props.product?.AirbusProprietary);

const isSaleUsed = computed(() => procurement.value?.procurementType === ProcurementType.Used);

const showLeasePrice = computed(
  () => isLeaseProcurement.value && (isTool.value || isProprietaryPart.value),
);
</script>

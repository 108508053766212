<template>
  <StandardDialogModal class="modal-request-exchange" :size="uiSize.Large" :loading="isBusy">
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="productId"
        :header-title="textK('UI_PROPRIETARY_PARTS_QUOTATION_REQUEST_EXCHANGE_MODAL_HEADER')"
        :procurement="procurement"
        :show-price="false"
      />
    </template>

    <template #body>
      <p class="pb-lg">{{ textK('UI_PROPRIETARY_PARTS_QUOTATION_DESCRIPTION') }}</p>
      <form>
        <div v-if="isSerialNumberRequired" class="flex gap-lg pb-lg">
          <VTextField
            v-model="formDetails.serialNumber"
            class="flex-grow"
            input-type="text"
            :type="UiVariant.Secondary"
            label="Serial number"
            :required="true"
          />
          <div class="flex-grow">
            <VTextField
              v-model="formDetails.flightHours"
              input-type="text"
              :type="UiVariant.Secondary"
              label="Flight hours/cycles"
              :required="!flyingHoursUnknown"
            />
            <Checkbox v-model="flyingHoursUnknown" class="modal-request-exchange__checkbox">
              <p>Flying hours/cycles unknown</p>
            </Checkbox>
          </div>
        </div>
        <div class="flex gap-lg pb-lg">
          <DatePickerWrapper
            :value="formDetails.removalDate"
            class="flex-grow"
            label="Maintenance period"
            datepicker-type="text"
            :min-date="dayjs().format(DisplayDateFormat)"
            @apply="applyRemovalDate"
            @cancelled="togglePicker"
          >
            <template #label>
              <VTextField
                :required="true"
                class="flex-grow removal-date"
                :type="UiVariant.Secondary"
                :label="'Removal date'"
                :model-value="formDetails.removalDate"
                :readonly="true"
                @click="togglePicker"
              >
              </VTextField>
            </template>
          </DatePickerWrapper>
          <VTextField
            v-model="formDetails.removalReason"
            class="flex-grow"
            input-type="text"
            :type="UiVariant.Secondary"
            label="Removal reason"
            :required="true"
          />
        </div>
        <div class="flex gap-lg">
          <VTextField
            v-model="formDetails.acReg"
            class="flex-grow"
            input-type="text"
            :type="UiVariant.Secondary"
            label="AC Reg. or MSN"
            :required="true"
          />
          <div class="flex-grow dropdown">
            <VSelect
              :modelValue="
                priorityOptions.findIndex((option) => option.value === formDetails.priority)
              "
              @update:modelValue="(value: string) => handlePriorityInput(value)"
              :required="true"
              :options-skeleton="priorityOptions.length === 0"
              label="Priority"
              :is-input-field="false"
              :options="priorityOptions"
              :type="UiVariant.Secondary"
              ui-test-name-dropdown="priority-dropdown-option"
            />
          </div>
        </div>
        <div class="modal-request-exchange_attachment">
          <div
            class="modal-request-exchange_attachment--box"
            :class="{
              'has-attachment': Boolean(formDetails.removalComponentTechnicalDocumentSheet),
            }"
          >
            <div
              v-if="!Boolean(formDetails.removalComponentTechnicalDocumentSheet)"
              class="upload-text"
            >
              <svg
                width="10"
                height="21"
                viewBox="0 0 10 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.16797 20.1875C4.25781 20.1875 2.70312 18.5742 2.70312 16.5898V5.51562C2.70312 4.19141 3.74219 3.11719 5.01953 3.11719C6.28906 3.11719 7.32422 4.19531 7.32422 5.51562V12.3555H6.23047V5.51562C6.23047 4.79687 5.6875 4.21094 5.01953 4.21094C4.34375 4.21094 3.79687 4.79687 3.79687 5.51562V16.5859C3.79687 17.9688 4.85937 19.0898 6.16797 19.0898C7.47656 19.0898 8.53906 17.9648 8.53906 16.5859L8.51172 5.67969C8.51172 3.62109 6.92187 1.94531 4.96875 1.94531C4.03516 1.94531 3.16406 2.32812 2.51562 3.02344C1.86328 3.72656 1.50391 4.66797 1.50391 5.67578V14.2266H0.410156V5.67969C0.410156 4.39453 0.875 3.1875 1.71484 2.28125C2.57031 1.35937 3.72656 0.851562 4.96484 0.851562C7.51953 0.851562 9.60156 3.01562 9.60156 5.67578L9.62891 16.582C9.63281 18.5703 8.07812 20.1875 6.16797 20.1875Z"
                  fill="currentColor"
                />
              </svg>
              <span
                ><a @click="uploadFile" class="upload-text__link">{{
                  textK('UI_PROPRIETARY_PARTS_QUOTATION_ATTACH_BUTTON')
                }}</a>
                {{ textK('UI_PROPRIETARY_PARTS_QUOTATION_ATTACH_INSTRUCTION') }}</span
              >
            </div>
            <div v-else>
              <div class="attachment-label">Attached file</div>
              <div class="attachment-title">
                <span>{{ formDetails?.removalComponentTechnicalDocumentSheet?.name }}</span>

                <Tooltip :position="TooltipDefaultPosition.Top">
                  <button
                    @click="formDetails.removalComponentTechnicalDocumentSheet = undefined"
                    class="attachment-title__delete"
                  >
                    <svgicon name="ui-delete"></svgicon>
                  </button>
                  <template #tooltip-content>
                    {{ textK('UI_PROPRIETARY_PARTS_QUOTATION_REMOVE_FILE') }}
                  </template>
                </Tooltip>
              </div>
            </div>
          </div>
          <a :href="downloadTemplateLink" class="download-link" target="_blank">{{
            textK('UI_PROPRIETARY_PARTS_QUOTATION_DOWNLOAD_TEMPLATE_CTA')
          }}</a>
        </div>
        <div>
          <VTextField
            v-model="formDetails.purchaseOrderNumber"
            class="flex-grow"
            input-type="text"
            :type="UiVariant.Secondary"
            label="Reference"
            :required="true"
            :vmax-length="11"
            :vmmin-length="3"
          />
        </div>
        <div class="py-lg">
          <VTextarea
            v-model="formDetails.message"
            :type="UiVariant.Secondary"
            label="Message"
            :vmax-length="1000"
          />
        </div>
      </form>
    </template>

    <template #actions>
      <VButton
        v-ui-test="'disable-account-ok-button'"
        :type="uiVariant.Default"
        :disabled="!isValid"
        @click="handleSubmit"
        >{{ textK('UI_PROPRIETARY_PARTS_QUOTATION_REQUEST_EXCHANGE_MODAL_SUBMIT') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-request-exchange.scss" lang="scss" scoped></style>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import dayjs from 'dayjs';
import {
  custom,
  flatten,
  forward,
  maxLength,
  minLength,
  nullable,
  nullish,
  object,
  parse,
  string,
} from 'valibot';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { useModalStore } from '@/src/core/stores/modal';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { NotificationTypes } from '@/src/core/types/api';
import {
  AirbusPart,
  RequestExchangeForm,
  TooltipDefaultPosition,
} from '@/src/core/types/interfaces';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { RequestExchangeApi } from '@/src/market/api/requestExchangeApi';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { priorityOptions } from '@/src/market/settings/priority';
import { useProductStore } from '@/src/market/stores/product';
import { useRequestExchangeFormStore } from '@/src/market/stores/request-exchange-form';
import { useUserStore } from '@/src/profile/stores/user';

interface Params {
  id: string;
  procurementType: ProcurementType;
}

const initialFormState: RequestExchangeForm = {
  serialNumber: '',
  removalDate: '',
  removalReason: '',
  acReg: '',
  purchaseOrderNumber: '',
  priority: 'RTN',
  removalComponentTechnicalDocumentSheet: undefined,
};

const textK = useText();
const formStore = useRequestExchangeFormStore();
const modalStore = useModalStore();
const userStore = useUserStore();
const notificationStore = useNotificationsStore();
const assistantStore = useAssistantStore();
const productStore = useProductStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const productId = (modalStore.params as Params)?.id;
const formStoreDetails = formStore.getForm(productId);
const formDetails = reactive<RequestExchangeForm>(
  formStoreDetails ? { ...formStoreDetails } : { ...initialFormState },
);
const priority = ref('');
const flyingHoursUnknown = ref(false);
const isDatePickerOpen = ref(false);
const isValid = ref(false);
const errors = ref<Partial<Record<any, [string, ...string[]]>> | null>(null);
const isBusy = ref(false);
const isSubmitted = ref(false);
const downloadTemplateLink = ref('');

const RequestExchangeSchema = object(
  {
    serialNumber: nullable(string([maxLength(255)])),
    flightHours: nullish(string()),
    removalDate: string([minLength(1)]),
    removalReason: string([minLength(1)]),
    acReg: string([minLength(1), maxLength(255)]),
    purchaseOrderNumber: string([minLength(3), maxLength(11)]),
    message: nullish(string([maxLength(1000)])),
  },
  [
    forward(
      custom(
        (input) =>
          isFlyingHoursNotRequired.value ||
          (typeof input.flightHours === 'string' && input.flightHours.length > 0),
        'Flying hours are required.',
      ),
      ['flightHours'],
    ),
    forward(
      custom(
        (input) =>
          isSerialNumberRequired.value === false ||
          (typeof input.serialNumber === 'string' && input.serialNumber.length > 0),
        'Serial number is required.',
      ),
      ['serialNumber'],
    ),
  ],
);

const isFlyingHoursNotRequired = computed(() => {
  return flyingHoursUnknown.value || !isSerialNumberRequired.value;
});

const procurementType = computed(() => {
  return (modalStore.params as Params)?.procurementType;
});

const product = computed(() => {
  return productStore.productById(productId);
});

const user = computed(() => {
  return userStore.currentUser;
});

const isSerialNumberRequired = computed(() => {
  return product.value?.SerialNumberRequired || false;
});

const additionalData = computed(() => {
  return productAdditionalData(productId, product.value?.OwningSystem ?? '');
});

const procurement = computed(() => {
  return (additionalData.value as AirbusPart)?.procurements.find(
    ({ procurementType }) => procurementType === procurementType,
  );
});

const closeModal = () => {
  modalStore.closeModal();
};

const handlePriorityInput = (value: string) => {
  formDetails['priority'] = priorityOptions[value].value;
  priority.value = priorityOptions[value].value;
};

const togglePicker = () => {
  isDatePickerOpen.value = !isDatePickerOpen.value;
};

const applyRemovalDate = (datepickerValue: string) => {
  formDetails.removalDate = dayjs(datepickerValue).format(DisplayDateFormat);
  togglePicker();
};

const uploadFile = () => {
  const inputElement: HTMLInputElement = document.createElement('input');
  inputElement.setAttribute('type', 'file');
  inputElement.setAttribute('accept', '.xlsx, .pdf');

  // Setup input event, before triggring click (IE/Edge needs this)
  inputElement.onchange = () => {
    if (inputElement.files) {
      handleUploadedFile(inputElement.files);
    }
  };

  inputElement.click();
};

const handleUploadedFile = (files: FileList) => {
  const fileForUpload = files[0];

  if (fileForUpload.size > 50000000) {
    notificationStore.addNotification({
      notificationItem: {
        Title: 'Upload failed',
        Description: 'The file is too large. Max file size is 50 mb',
        Type: NotificationTypes.Error,
        Timing: 10000,
      },
    });

    assistantStore.closeAssistant();
  } else if (
    fileForUpload.type !== 'application/pdf' &&
    fileForUpload.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    notificationStore.addNotification({
      notificationItem: {
        Title: 'Upload failed',
        Description: 'Accepted file formats are Excel and PDF.',
        Type: NotificationTypes.Error,
        Timing: 10000,
      },
    });

    assistantStore.closeAssistant();
  } else {
    const reader = new FileReader();

    reader.onload = function (e) {
      const fileContent = (e.target?.result as string).split(',')[1];

      const fileData = {
        content: fileContent,
        mimeType: fileForUpload.type,
        name: fileForUpload.name,
      };

      formDetails.removalComponentTechnicalDocumentSheet = fileData;
    };

    reader.readAsDataURL(fileForUpload);
  }
};

const fetchDownloadTemplateLink = async () => {
  const { IsSuccess, Data } = await Req({
    url: RequestExchangeApi.DownloadTemplate,
    method: 'GET',
  });

  if (IsSuccess && Data) {
    downloadTemplateLink.value = Data;
  }
};

const handleSubmit = async () => {
  const { GlobalId } = userStore.currentUser;
  isBusy.value = true;

  const { IsSuccess } = await Req({
    url: RequestExchangeApi.RequestExchangeFormSubmit(GlobalId),
    method: 'POST',
    data: {
      ...formDetails,
      procurementType: procurementType.value,
      customerName: user.value.Name,
      customerEmail: user.value.Email,
      companyData: `${user.value.Unit?.BlueB2BUnit?.Name} (${user.value.Unit?.BlueB2BUnit?.Uid})`,
      productId: product.value?.Id || '',
    },
  });

  if (IsSuccess) {
    closeModal();
    formStore.clearForm(productId);
    isSubmitted.value = true;

    //open confirmation modal
    modalStore.showModal({
      modalComponent: 'ModalQuoteEmailNotification',
      params: {
        key: 'AirbusRFQConfirmationPartExchange_ModalUrl',
        title: 'AirbusRFQConfirmationPartExchange_Name',
        size: uiSize.Medium,
        isConfirmation: true,
        withCloseButton: true,
      },
    });
  }

  isBusy.value = false;
};

const checkFormValidity = (newValue: RequestExchangeForm) => {
  try {
    parse(RequestExchangeSchema, { ...newValue });
    isValid.value = true;
    errors.value = null;
  } catch (error) {
    isValid.value = false;
    errors.value = flatten<typeof RequestExchangeSchema>(error).nested;
  }
};

watch(
  formDetails,
  (newValue) => {
    checkFormValidity(newValue);
  },
  { immediate: true },
);

watch(
  flyingHoursUnknown,
  () => {
    checkFormValidity(formDetails);
  },
  { immediate: true },
);

onMounted(async () => {
  if (!userStore.currentUser) {
    await userStore.fetchUserProfile();
  }

  await fetchDownloadTemplateLink();
});

onBeforeUnmount(() => {
  if (!isSubmitted.value) {
    formStore.saveForm(productId, { ...formDetails });
  }
});
</script>

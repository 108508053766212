<template>
  <div class="orders-filters" :class="{ 'orders-filters--modal': modal }">
    <div class="orders-filters__group">
      <div class="orders-filters__group-name">
        {{ textK('UI_COMMON_SEARCH_FILTER_TITLE') }}
        <button
          v-if="isShowSearchForClear"
          class="orders-filters__clear-filter text-button"
          @click="clearSearchFilters()"
        >
          {{ textK('UI_COMMON_CLEAR_FILTERS') }}
        </button>
      </div>
      <VTextField
        v-ui-test="'product-number'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="product-number"
        :label="textK('UI_COMMON_PART_NO')"
        v-model="productNumberQuery"
        @update:modelValue="searchHandler"
      >
      </VTextField>
      <VTextField
        v-ui-test="'purchase-order-number'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="purchase-order-number"
        :label="textK('UI_COMMON_PO_NO')"
        v-model="purchaseOrderNumberQuery"
        @update:modelValue="searchHandler"
      >
      </VTextField>
      <VTextField
        v-ui-test="'order-number'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="order-number"
        :label="textK('UI_COMMON_ORDER_NO')"
        v-model="orderNumberQuery"
        @update:modelValue="searchHandler"
      >
      </VTextField>
      <VTextField
        v-ui-test="'master-shop-name'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="master-shop-name"
        :label="textK('UI_COMMON_SELLER')"
        v-model="masterShopNameQuery"
        @update:modelValue="searchHandler"
      >
      </VTextField>
    </div>
    <div class="orders-filters__group">
      <div class="orders-filters__group-name">
        {{ textK('UI_COMMON_SEARCH_FILTER_BY_LABEL') }}
      </div>
      <VSelect
        v-ui-test="'toolbar-filter-priority'"
        class="orders-filters__group-input"
        name="priority-select"
        :options="priorityOptions"
        v-model="selectedPriority"
        :label="textK('UI_COMMON_PRIORITY')"
        @update:modelValue="searchHandler"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
      ></VSelect>
      <VSelect
        v-ui-test="'toolbar-filter-order-type'"
        class="orders-filters__group-input"
        name="order-type-select"
        :options="orderTypeOptions"
        v-model="selectedOrderType"
        :label="textK('UI_COMMON_ORDER_TYPE')"
        @update:modelValue="searchHandler"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
      ></VSelect>
      <VSelect
        v-ui-test="'toolbar-filter-orders'"
        class="orders-filters__group-input"
        name="status-select"
        :options="status"
        v-model="selectedStatus"
        :label="textK('UI_ORDERS_SEARCH_STATUS_LABEL')"
        @update:modelValue="searchHandler"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
      ></VSelect>
    </div>

    <div class="orders-filters__group">
      <div class="orders-filters__group-name">
        {{ textK('UI_COMMON_PERIOD_TITLE') }}
        <button
          v-if="selectedTimePeriod"
          class="orders-filters__clear-filter text-button"
          @click="clearDateFilters()"
        >
          {{ textK('UI_COMMON_CLEAR_PERIOD') }}
        </button>
      </div>
      <VSelect
        v-ui-test="'toolbar-filter-period'"
        class="orders-filters__group-input"
        name="period-select"
        :options="timePeriod"
        v-model="selectedTimePeriod"
        :label="textK('UI_COMMON_PERIOD_SELECT_LABEL')"
        @update:modelValue="searchHandler"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
      ></VSelect>
      <DatePickerWrapper
        ref="datepicker"
        class="orders-filters__group-input"
        :fromDate="fromDate"
        :toDate="toDate"
        :minDate="dayjs().subtract(2, 'year').format(DisplayDateFormat)"
        :maxDate="dayjs().format(DisplayDateFormat)"
        :range="true"
        :multi-calendars="true"
        :auto-position="true"
        @apply="applyDate"
      >
        <template #label v-if="selectedTimePeriod">
          <VTextField
            class="orders-filters__group-input"
            :label="textK('UI_COMMON_PERIOD_DATEPICKER_LABEL')"
            name="selected-time-period"
            :model-value="dates"
            :type="modal ? uiVariant.Secondary : uiVariant.Primary"
            :silent-disabled="true"
            :as-span="true"
          />
        </template>
      </DatePickerWrapper>
    </div>
  </div>
</template>

<style src="./orders-filters.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { computed, ref } from 'vue';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useTextsStore } from '@/src/core/stores/texts';
import { IVSelect } from '@/src/core/types/ui';
import { DisplayDateFormat, InputDateFormat } from '@/src/core/utils/dates';
import {
  ORDER_DATE_FILTER_LIST,
  ORDER_SEARCH_FACET_LIST,
  OrdersService,
} from '@/src/market/services/orders-service';
import { useOrdersStore } from '@/src/market/stores/orders';

interface Props {
  modal?: boolean;
}

withDefaults(defineProps<Props>(), {
  modal: false,
});

const textK = useText();
const authenticationStore = useAuthenticationStore();
const ordersStore = useOrdersStore();
const textsStore = useTextsStore();

const datepicker = ref<typeof DatePickerWrapper | null>(null);
const uiVariant = UiVariant;

const orderFilters = computed(() => {
  return ordersStore.filters;
});

/*
 * Product Number
 */

const productNumberQuery = computed({
  get: () => {
    return orderFilters.value?.manufacturerAID || '';
  },
  set: (query: string) => {
    ordersStore.updateOrderListFilters({
      Query: { manufacturerAID: query },
    });
  },
});

/*
 * Product Order Number
 */

const purchaseOrderNumberQuery = computed({
  get: () => {
    return orderFilters.value?.purchaseOrderNumber || '';
  },
  set: (query: string) => {
    ordersStore.updateOrderListFilters({
      Query: { purchaseOrderNumber: query },
    });
  },
});

/*
 * Order Number
 */

const orderNumberQuery = computed({
  get: () => {
    return orderFilters.value?.code || '';
  },
  set: (query: string) => {
    ordersStore.updateOrderListFilters({
      Query: { code: query },
    });
  },
});

/*
 * Seller
 */

const masterShopNameQuery = computed({
  get: () => {
    return orderFilters.value?.masterShopName || '';
  },
  set: (query: string) => {
    ordersStore.updateOrderListFilters({
      Query: { masterShopName: query },
    });
  },
});

/**
 * Search For Clear Button
 */

const isShowSearchForClear = computed(() => {
  return (
    productNumberQuery.value ||
    purchaseOrderNumberQuery.value ||
    orderNumberQuery.value ||
    masterShopNameQuery.value
  );
});

/*
 * Priority
 */

const selectedPriority = computed({
  get: () => {
    const priorityIndex = priorityOptions.value?.findIndex(
      (priority) => priority.value === orderFilters.value?.priority,
    );

    return priorityIndex === -1 ? 0 : priorityIndex;
  },
  set: (index: number) => {
    const currentValue = priorityOptions.value?.[index].value;
    ordersStore.updateOrderListFilters({
      Query: { priority: currentValue },
    });
  },
});

const priorityOptions = computed(() => {
  if (textsStore.isBusy) {
    return [];
  }

  return [
    { label: 'All', value: '', hideValueWhenSelected: true },
    { label: TextKey('UI_COMMON_ROUTINE'), value: 'RTN' },
    { label: TextKey('UI_COMMON_WORK_STOPPAGE'), value: 'WSP' },
    { label: TextKey('UI_COMMON_URGENT_STOCK_REPLENISHMENT'), value: 'USR' },
    { label: TextKey('UI_COMMON_AIRCRAFT_ON_GROUND'), value: 'AOG' },
  ];
});

/**
 * Order Type
 */

const selectedOrderType = computed({
  get: () => {
    const orderTypeIndex = orderTypeOptions.value?.findIndex(
      (orderType) => orderType.value === orderFilters.value?.orderType,
    );
    return orderTypeIndex === -1 ? 0 : orderTypeIndex;
  },
  set: (index: number) => {
    const currentValue = orderTypeOptions.value?.[index].value;
    ordersStore.updateOrderListFilters({
      Query: { orderType: currentValue },
    });
  },
});

const orderTypeOptions = computed(() => {
  if (textsStore.isBusy) {
    return [];
  }

  return [
    { label: 'All', value: '', hideValueWhenSelected: true },
    { label: TextKey('UI_COMMON_ORDER_TYPE_STANDARD_ORDER'), value: 'Standard Order' },
    {
      label: TextKey('UI_COMMON_ORDER_TYPE_INITIAL_PROVISIONING_ORDER'),
      value: 'Initial Provisioning',
    },
    {
      label: TextKey('UI_COMMON_ORDER_TYPE_FREE_OF_CHARGE'),
      value: 'Free of Charge',
    },
  ];
});

/*
 * Status
 */

const selectedStatus = computed({
  get: () => {
    const statusIndex = status.findIndex((status) => status.value === orderFilters.value?.status);
    return statusIndex === -1 ? 0 : statusIndex;
  },
  set: (index: number) => {
    const currentValue = status[index].value;
    ordersStore.updateOrderListFilters({
      Query: { status: currentValue },
    });
  },
});

const status: IVSelect[] = [
  { label: 'All', value: '', hideValueWhenSelected: true },
  { label: 'Receipt', value: 'processing' },
  { label: 'Processing', value: 'created' },
  { label: 'Shipping in progress', value: 'ready' },
  { label: 'Picked up by forwarder', value: 'dispatched' },
  { label: 'Cancelled', value: 'cancelled' },
];

/*
 * TIME PERIOD
 */

const selectedTimePeriod = computed({
  get: () => {
    const timePeriodIndex = timePeriod.findIndex(
      (timePeriod) => timePeriod.value === orderFilters.value?.timePeriod,
    );
    return timePeriodIndex === -1 ? 0 : timePeriodIndex;
  },
  set: (index: number) => {
    const currentValue = timePeriod[index].value;

    if (currentValue === 'custom') {
      openDatepicker();
      return;
    }

    ordersStore.updateOrderListFilters({
      Query: { timePeriod: currentValue },
    });

    fromDate.value = OrdersService.getFromDate(ordersStore.filters);
    toDate.value = OrdersService.getToDate(ordersStore.filters);
  },
});

const timePeriod: IVSelect[] = [
  { label: 'To date', value: '', hideValueWhenSelected: true },
  { label: 'Current month', value: 'current-month' },
  { label: 'Last month', value: 'last-month' },
  { label: 'Current year', value: 'current-year' },
  { label: 'Custom', value: 'custom' },
];

/*
 * TIME PERIOD DATE FIELD
 */

const fromDate = computed({
  get: () => {
    return orderFilters.value?.fromDate;
  },
  set: (value) => {
    ordersStore.updateOrderListFilters({
      Query: {
        fromDate: value ? dayjs(value, InputDateFormat).format(DisplayDateFormat) : undefined,
      },
    });
  },
});

const toDate = computed({
  get: () => {
    return orderFilters.value?.toDate;
  },
  set: (value) => {
    ordersStore.updateOrderListFilters({
      Query: {
        toDate: value ? dayjs(value, InputDateFormat).format(DisplayDateFormat) : undefined,
      },
    });
  },
});

const dates = computed({
  get: () => {
    return fromDate.value + ' - ' + toDate.value;
  },
  set: (value) => {
    if (Array.isArray(value)) {
      fromDate.value = value[0];
      toDate.value = value[1];
    }

    ordersStore.updateOrderListFilters({
      Query: { timePeriod: 'custom' },
    });
  },
});

const openDatepicker = () => {
  if (!datepicker.value) {
    return;
  }

  datepicker.value.openMenu();
};

const applyDate = (datepickerValue: string) => {
  dates.value = datepickerValue;
  search();
};

const searchHandler = debounce(() => {
  search();
}, 300);

const search = async () => {
  if (!readOnlyAccount.value) {
    ordersStore.fetchOrdersList();
  }
};

const readOnlyAccount = computed(() => {
  return authenticationStore.readOnlyAccount;
});

const clearSearchFilters = () => {
  ordersStore.clearFilterGroup(ORDER_SEARCH_FACET_LIST);
  search();
};

const clearDateFilters = () => {
  ordersStore.clearFilterGroup(ORDER_DATE_FILTER_LIST);
  search();
};
</script>

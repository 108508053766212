import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { OwningSystem } from '@/src/core/types/api';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { AIRBUS_FHS_SELLER_KEY, AIRBUS_SELLER_KEY } from '@/src/market/utils/seller-key';
import { useUserStore } from '@/src/profile/stores/user';

export async function getProductSkuAsync(sku: string) {
  const { IsSuccess, Data } = await Req({
    url: ProductApi.ProductSku(),
    params: {
      sku,
    },
    headers: { BypassNotifications: true },
  });

  if (IsSuccess) {
    return Data;
  } else {
    return undefined;
  }
}

export async function getProductOfferAsync(id: string) {
  const { IsSuccess, Data } = await Req({
    url: ProductApi.ProductOffer(id),
    headers: { BypassNotifications: true },
  });

  if (IsSuccess) {
    return Data;
  } else {
    return undefined;
  }
}

export async function getProductInfoAsync(id: string) {
  const { IsSuccess, Data } = await Req({
    url: ProductApi.ProductInfo(id),
  });

  if (IsSuccess) {
    return Data;
  }
}

export const isAirbusProduct = (owningSystem?: string): boolean => {
  return owningSystem?.toLowerCase() === 'blue';
};

export const fetchProductAdditionalDataByUserOwningSystem = (
  id: string,
  productOwningSystem: string,
  quantity?: number,
) => {
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const productPriceStore = useProductPriceInfoStore();
  const airbusPartStore = useAirbusPartStore();
  const userStore = useUserStore();

  if (id) {
    if (userStore.blueCustomer && productOwningSystem === OwningSystem.BLUE) {
      airbusPartStore.queueAirbusParts({
        OfferId: id,
        Quantity: 1,
      });
    }

    if (userStore.redCustomer && productOwningSystem !== OwningSystem.BLUE) {
      productAdditionalInfoStore.queueAdditionalProductData({
        OfferId: id,
        Quantity: quantity || 1,
      });
      productPriceStore.queueAdditionalProductData({
        OfferId: id,
        Quantity: quantity || 1,
      });
    }
  }
};

/**
 * Convert the Airbus MarketOfferUrl to AirbusFhs
 *
 * @param url
 * @returns string
 */
export const transformAirbusLinkToFhs = (url: string) => {
  const airbusLink = url.includes(AIRBUS_SELLER_KEY);
  if (airbusLink) {
    return url.replace(AIRBUS_SELLER_KEY, AIRBUS_FHS_SELLER_KEY);
  }
  return url;
};

/**
 * Convert airbusfhs text to airbus
 *
 * @param text
 * @returns string
 */
export const transformAirbusFhsToAirbus = (text: string) => {
  const airbusFhs = text.includes(AIRBUS_FHS_SELLER_KEY);
  if (airbusFhs) {
    return text.replace(AIRBUS_FHS_SELLER_KEY, AIRBUS_SELLER_KEY);
  }
  return text;
};

<template>
  <div class="product__company-logo-wrapper">
    <span class="product__company-logo-title">{{ textK('UI_PRODUCT_SOLD_HANDLED_BY') }}</span>
    <img class="product__company-logo" v-if="logo.url" :src="logo.url" :alt="logo.name" />
    <span class="product__supplier-text" v-else v-ui-test="'supplier'" :title="titleTag(logo.name)">
      {{ logo.name }}
    </span>
  </div>
</template>

<style lang="scss" src="./product-company-logo.scss" scoped></style>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';

interface Props {
  logo: { url: string; name: string };
}

defineProps<Props>();
const textK = useText();
const titleTag = useTitleTag;
</script>

import { MenuQuicklinkGroup, NotificationContext, NotificationTypes } from '@/src/core/types/api';

export interface INotificationList {
  Notifications: INotificationItem[];
}

export interface INotificationGlobal {
  Type?: NotificationTypes;
  Body?: string;
  NotificationAction?: INotificationAction;
}

export interface INotificationItem {
  Title?: string;
  Description?: string;
  Type: NotificationTypes;
  Timing?: number;
  Id?: string;
  NotificationAction?: INotificationAction;
  TypeDescription?: string;
  Context?: NotificationContext | NotificationContext.Normal;
}

export interface INotificationAction {
  Name?: string;
  Url?: string;
  StringOne?: string;
  StringTwo?: string;
  Modal?: IModal[];
}

export interface IModalGroup {
  modals: IModal[];
}

export interface IModal {
  modalComponent: string | null;
  modalLocked?: boolean;
  params?: string | object;
}

export interface ISCrollLock {
  id: string;
  scrollPosition?: number;
  type?: 'select';
}

export interface IAssistant {
  assistantState: AssistantStates;
  assistantComponent: string | null;
  assistantLocked?: boolean;
  params?: string | object;
}

export interface IQuickLinkMenu {
  links?: MenuQuicklinkGroup;
  linkGroups?: MenuQuicklinkGroup[];
  isBusy: boolean;
}

export enum AssistantStates {
  Hidden,
  FullScreen,
  Minified,
}

export interface IWindowServerRendered extends Window {
  srContent?: HTMLElement;
  srProduct?: HTMLElement;
  srSeller?: HTMLElement;
  srData?: object;
}

export type LoginCredentials =
  | {
      userId: string;
      password: string;
      rememberMe?: boolean;
    }
  | {
      mssoToken: string;
      mssoRefreshToken: string;
    };

export interface IVSelect {
  index?: number | null;
  // tslint:disable-next-line:no-any
  [key: string]: any;
  value: string;
  action?: () => void;
  disabled?: boolean;
  hideValueWhenSelected?: boolean;
}

export interface IRenameCartModalParams {
  Name: string;
  Code: string;
  cartName: string;
}

export interface IProductTabs {
  label: string;
  value: string;
}

export enum CampaignColorThemes {
  Default = 'default',
  Blue = 'blue',
  Green = 'green',
  Grey = 'grey',
  Black = 'black',
  White = 'white',
  Lilly = 'lilly',
  SecondaryPink = 'secondary-pink',
  TertiaryMarketplaceGreen = 'tertiary-marketplace-green',
  TertiaryContentGreen = 'tertiary-content-green',
}

export enum UserIndicationTypes {
  CUSTOMER = 'CUSTOMER_USER',
  SHOP = 'SHOP_USER',
  OPERATOR = 'OPERATOR_USER',
}

export const ACCOUNT_GROUP_US = 'US';
export const ACCOUNT_GROUP_NON_US = 'NON_US';

export const GENERAL_TERMS = 'GeneralTerms';
export const AIRBUS_NON_US_TERMS = 'AirbusNONUSTerms';
export const AIRBUS_US_TERMS = 'AirbusUSTerms';
export const AIRBUS_LEASE_TERMS = 'AirbusLeaseAndTerms';

export type TermsPageName =
  | typeof GENERAL_TERMS
  | typeof AIRBUS_NON_US_TERMS
  | typeof AIRBUS_US_TERMS
  | typeof AIRBUS_LEASE_TERMS;

export const PROCUREMENT_REMARK_US = 'ProcurementRemarkUS';
export const PROCUREMENT_REMARK_NON_US = 'ProcurementRemarkNONUS';

<template>
  <StandardDialogModal
    class="modal-request-quote-fhs"
    :size="uiSize.Large"
    :loading="isBusy"
    action-button-alignment="right"
  >
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="id"
        :header-title="textK('UI_FHS_QUOTATION_QUOTE_HEADER')"
      />
    </template>
    <template #body>
      <p class="pb-xl">
        {{ textK('UI_FHS_QUOTATION_QUOTE_DESCRUPTION') }}
      </p>
      <VTextField
        class="pb-xl"
        v-model="formData.quotationReference"
        input-type="text"
        :type="uiVariant.Secondary"
        :label="textK('UI_FHS_QUOTATION_REFERENCE_TEXT')"
        name="referenceNumber"
        :required="true"
        :vmax-length="MAX_REFERENCE_LENGTH"
        regex="^[A-Za-z0-9\s\-]+$"
      />
      <VTextarea
        class="pb-l"
        v-model="formData.remarks"
        :vmax-length="MAX_MESSAGE_LENGTH"
        :type="uiVariant.Secondary"
        :label="textK('UI_FHS_QUOTATION_MESSAGE')"
      />
    </template>
    <template #actions>
      <div class="modal-request-quote-fhs__actions-wrapper">
        <VButton
          @click.prevent="onSubmit"
          :disabled="!isValid"
          :loading="isBusy"
          :type="uiVariant.Default"
          v-ui-test="'submit-aribus-fhs-quote'"
          >{{ textK('UI_FHS_QUOTATION_BUTTON_LABEL') }}
        </VButton>
      </div>
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useModalStore } from '@/src/core/stores/modal';
import { SendQuoteEmailToAirbusRequest } from '@/src/core/types/api';
import { QuoteApi } from '@/src/market/api/quoteApi';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, reactive, ref } from 'vue';

interface Params {
  id: string;
  procurementType: string;
}

interface Form {
  quotationReference: string;
  remarks?: string;
}

const MAX_MESSAGE_LENGTH = 1000;
const MAX_REFERENCE_LENGTH = 11;

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const userStore = useUserStore();
const fhsDetailsStore = useProductFHSDetailsStore();

const formData = reactive<Form>({
  quotationReference: '',
});
const isBusy = ref(false);
const isInputFocused = ref(false);
const isValid = computed(() => formData.quotationReference.length > 0 && !isInputFocused.value);
const id = computed(() => (modalStore.params as Params)?.id ?? '');
const procurementType = computed(
  () => (modalStore.params as Params)?.procurementType.toUpperCase() ?? '',
);

const fshDetailsByProcurement = computed(() =>
  fhsDetailsStore.getFhsDetailsByProductIdAndProcurement(id.value, procurementType.value),
);
const fhsLocation = computed(() => {
  const locations = fshDetailsByProcurement.value?.locations ?? [];
  if (locations.length > 0) {
    return locations[0];
  }
  return '';
});
const supplier = computed(() => fshDetailsByProcurement.value?.supplier);

const closeModal = () => {
  modalStore.closeModal();
};

const modalConfirmationBlock = computed(() => {
  switch (procurementType.value) {
    case ProcurementType.Repair:
      return 'AirbusRFQConfirmationPartRepair';
    case ProcurementType.Lease:
      return 'AirbusRFQConfirmationPartLease';
    default:
      return 'AirbusRFQConfirmationPartExchange';
  }
});

const onSubmit = async () => {
  isBusy.value = true;

  const { Name: companyData } = userStore.company;
  const { Name: customerName, Email: customerEmail } = userStore.currentUser;

  const requestObejct: SendQuoteEmailToAirbusRequest = {
    ...formData,
    requestedQuantity: 1,
    customerName,
    customerEmail,
    companyData,
    productId: id.value,
    procurementType: procurementType.value,
    supplier: supplier.value,
    location: fhsLocation.value,
  };

  const { IsSuccess } = await Req({
    url: QuoteApi.CreateAirbusQuoteFHS,
    method: 'POST',
    data: requestObejct,
  });

  if (IsSuccess) {
    modalStore.showModal({
      modalComponent: 'ModalQuoteEmailNotification',
      params: {
        key: `${modalConfirmationBlock.value}_ModalUrl`,
        title: `${modalConfirmationBlock.value}_Name`,
        size: uiSize.Medium,
        isConfirmation: true,
        withCloseButton: true,
      },
    });

    closeModal();
  }

  isBusy.value = false;
};
</script>

<template>
  <div
    class="quantity-selector"
    :class="{
      'quantity-selector--is-busy': cartIsDeleting,
      'quantity-selector--list': !!listId,
      'quantity-selector--subtile': subtile,
      'quantity-selector--as-input': asInput,
    }"
  >
    <div class="quantity-selector-wrapper">
      <transition name="fadeLeft">
        <button
          class="quantity-selector-button"
          v-ui-test="'quantity-decrease'"
          :class="{
            'quantity-selector-button--is-busy': queuingAddToCart && qtyRequest <= 0,
          }"
          v-if="!hasAddToCart || +modelValue > 0"
          :disabled="isDecreaseBtnDisabled"
          @click.stop="decrease"
        >
          <span class="quantity-selector-button__char">–</span>
        </button>
      </transition>
      <transition name="fadeUp">
        <div class="quantity-selector-input" v-if="!hasAddToCart || +modelValue > 0">
          <div class="quantity-selector-input__unit" v-ui-test="'quantity-value-unit'">
            {{ unit }}
          </div>
          <input
            ref="inputfield"
            class="quantity-selector-input__field"
            :class="{
              'quantity-selector-input__field--small': newValue && newValue.length > 4,
            }"
            :maxlength="maxQtyLength"
            pattern="[0-9\/]*"
            v-ui-test="'quantity-value'"
            v-model="newValue"
            @keypress.stop
            @click.stop
            @keypress="onlyNumbers($event)"
            @keydown.stop="onKeyup($event)"
            @blur="onBlur"
            @focus.stop="onFocus"
            data-hj-whitelist
          />
        </div>
      </transition>
      <transition name="fadeRight">
        <button
          v-if="!hasAddToCart || +modelValue > 0"
          class="quantity-selector-button"
          v-ui-test="'quantity-increase'"
          :disabled="isIncreaseBtnDisabled"
          @click.stop="increase"
        >
          <span class="quantity-selector-button__char">+</span>
        </button>
      </transition>
    </div>
    <transition name="fadeDown">
      <div class="quantity-selector-add" v-if="hasAddToCart && +modelValue <= 0">
        <button
          v-if="hasAddToCart"
          v-ui-test="'add-to-cart-button'"
          class="quantity-selector-add-to-cart quantity-selector-add__to-cart"
          @click.stop="addToCart()"
        >
          {{
            bidOrQuote && pageName === 'search'
              ? textK('UI_SEARCHRESULT_ADD_TO_CART')
              : textK('UI_COMMON_ADD_TO_CART')
          }}
        </button>
        <button
          class="quantity-selector-add__bit-quote"
          v-if="bidOrQuote && pageName === 'search' && !isMarketplace && !isReadOnlyUser"
          @click.stop="openQuoteModal"
        >
          {{ textK('UI_SEARCHRESULT_QUOTE') }}
        </button>
        <button
          class="quantity-selector-add__bit-quote"
          v-if="bidOrQuote && pageName === 'search' && isMarketplace && !isReadOnlyUser"
          @click.stop="
            openModal({
              modalComponent: 'ModalRequestQuote',
              params: {
                id: productId,
                sellerId: sellerId,
                isMarketplace: isMarketplace,
              },
            })
          "
        >
          {{ textK('UI_SEARCHRESULT_BID') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<style src="./quantity-selector.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { TrackingCache } from '@/src/core/services/tracking-utils-service';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { CartEntry, NotificationTypes, OrderLine, OwningSystem } from '@/src/core/types/api';
import { IListEntry } from '@/src/core/types/interfaces';
import { IeKeys } from '@/src/core/types/keys';
import { numberRegEx } from '@/src/core/utils/regex-library';
import { SearchEventBus } from '@/src/core/utils/search-event-bus';
import { delay } from '@/src/core/utils/timing';
import { checkCartPriority } from '@/src/market/services/cart-service';
import { useCartStore } from '@/src/market/stores/cart';
import { useListsStore } from '@/src/market/stores/list';
import { useProductStore } from '@/src/market/stores/product';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { debounce } from 'lodash';
import { Key } from 'ts-key-enum';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Props {
  entry?: CartEntry | OrderLine | IListEntry;
  modelValue?: number | string;
  minValue?: number;
  maxValue?: number;
  step?: number;
  unit?: string;
  hasAddToCart?: boolean;
  subtile?: boolean;
  asInput?: boolean;
  avoidZeroValue?: boolean;
  quoteItem?: boolean;
  bidOrQuote?: boolean;
  isQuoteModal?: boolean;
  isMarketplace?: boolean;
  isAirbus?: boolean;
  isAirbusQuote?: boolean;
  isRequestQuote?: boolean;
  withDelay?: boolean;
  listId?: string;
  productId?: string;
  sellerId?: string;
  procurementType?: ProcurementType;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: 0,
  minValue: 1,
  maxValue: 999999,
  step: 1,
  unit: '-',
  hasAddToCart: false,
  subtile: false,
  asInput: false,
  avoidZeroValue: false,
  quoteItem: false,
  bidOrQuote: false,
  isQuoteModal: false,
  isMarketplace: false,
  isAirbus: false,
  isAirbusQuote: false,
  isRequestQuote: false,
  withDelay: true,
  listId: undefined,
  productId: undefined,
  sellerId: undefined,
  procurementType: undefined,
  entry: undefined,
});

const textK = useText();
const openModal = useModal();
const productStore = useProductStore();
const listsStore = useListsStore();
const notificationsStore = useNotificationsStore();
const modalStore = useModalStore();
const cartStore = useCartStore();
const quotesStore = useQuotesStore();
const authenticationStore = useAuthenticationStore();
const router = useRouter();
const route = useRoute();
const emit = defineEmits([
  'selectorAddToCart',
  'addToCart',
  'keyup',
  'change',
  'update:modelValue',
  'focus',
  'blur',
]);

const newValue = ref<string>('0');
const activeChange = ref<boolean>(false);
const qtyRequest = ref<number>(0);

const inputfield = ref<HTMLInputElement | null>(null);

const isReadOnlyUser = computed(() => authenticationStore.isReadOnly);

const pageName = computed(() => {
  return router.currentRoute.value.name;
});

const cartIsDeleting = computed(() => {
  return cartStore.isDeleting;
});

const queuingAddToCart = computed(() => {
  return cartStore.addToCartQueue.length > 0;
});

const maxQtyLength = computed(() => {
  return props.maxValue && props.maxValue.toString().length;
});

const isDecreaseBtnDisabled = computed(() => {
  if (props.isAirbusQuote) {
    return false;
  }

  return +props.value <= 0 || (props.quoteItem && +props.value <= props.minValue);
});

const isIncreaseBtnDisabled = computed(() => {
  if (props.isAirbusQuote) {
    return false;
  }

  return +newValue.value >= props.maxValue;
});

const addToCart = async () => {
  if (props.minValue > 1) {
    roundUpToMinQuantity(props.minValue);
  }

  adjustQuantity(props.minValue);
  emit('selectorAddToCart', newValue.value);
  emit('addToCart', newValue.value);

  await productStore.fetchAndUpdateProductByOfferId({ id: props.productId!, force: true });

  const productData = productStore.productById(props.productId!);

  if (productData && productData.IsTool && productData.OwningSystem === OwningSystem.BLUE) {
    checkCartPriority();
  }

  if (productData) {
    AdobeLaunchTracking.addToCart(productData);
  }

  SearchEventBus.$emit('addToCartClick');
};

const onlyNumbers = ($event: KeyboardEvent) => {
  const key: string = $event.key;

  if ($event.metaKey) {
    return;
  }

  switch (key) {
    case Key.ArrowUp:
    case IeKeys.ArrowUp:
    case Key.ArrowDown:
    case IeKeys.ArrowDown:
    case Key.Backspace:
    case Key.Tab:
    case Key.ArrowRight:
    case Key.ArrowLeft:
    case IeKeys.ArrowRight:
    case IeKeys.ArrowLeft:
      break;
    default:
      if (!numberRegEx.test(key)) {
        $event.returnValue = false;
        $event.preventDefault();
      }
  }
};

const onKeyup = ($event: KeyboardEvent) => {
  const key: string = $event.key;

  switch (key) {
    case Key.ArrowUp:
    case IeKeys.ArrowUp:
      $event.preventDefault();
      increase();
      break;
    case Key.ArrowDown:
    case IeKeys.ArrowDown:
      $event.preventDefault();
      decrease();
      break;
    case Key.Enter:
      blurInput();
      break;
  }

  emit('keyup', $event);
};

const adjustQuantity = async (adjust: number) => {
  let adjustedValue: number = parseInt(newValue.value, 10);
  const lessThanMin: boolean =
    adjust < props.minValue && +props.modelValue <= adjust && props.minValue > 1;

  if (Number.isNaN(adjust) || (!props.quoteItem && adjust <= 0)) {
    adjustedValue = 0;
  } else if (adjust < props.minValue) {
    adjustedValue = props.minValue;
    roundUpToMinQuantity(props.minValue);
  } else if (props.maxValue > 0 && adjust > props.maxValue) {
    adjustedValue = props.maxValue;
  } else {
    if (lessThanMin) {
      roundUpToMinQuantity(props.minValue);
    }

    adjustedValue = adjust;
  }

  if (adjust > props.maxValue) {
    roundUpToMaxQuantityNotification();
  }

  newValue.value = adjustedValue.toString();
  quantityHandler();
  handleTrackingQuantityChange(adjust);
};

const handleTrackingQuantityChange = (adjustedValue: number) => {
  let entry;
  const cartEntry = cartStore.getCartEntry(props.productId!);
  const listEntry = listsStore.getListEntry(props.productId!);

  if (Object.keys(cartEntry).length > 0) {
    entry = cartEntry;
  } else if (listEntry) {
    entry = listEntry;
  }

  if (entry) {
    if (adjustedValue === 0) {
      if (props.entry?.EntryNumber !== undefined) {
        cartStore.deleteCartEntry({
          cartCode: cartStore.currentCartCode,
          entryId: props.entry?.EntryNumber,
        });
        const cartEntry = cartStore.getCartEntry(props.productId!);
        if (cartEntry) {
          AdobeLaunchTracking.removeFromCart(cartEntry);
        }
      } else if (props.listId) {
        listsStore.deleteListEntry({ listCode: props.listId, productId: props.productId! });
      }
    } else if (adjustedValue > +props.modelValue) {
      AdobeLaunchTracking.cartVarying(
        entry as CartEntry,
        Number(props.modelValue),
        adjustedValue,
        'cart_qty_increased',
      );
    } else if (adjustedValue < +props.modelValue) {
      AdobeLaunchTracking.cartVarying(
        entry as CartEntry,
        Number(props.modelValue),
        adjustedValue,
        'cart_qty_decreased',
      );
    }
  }
};

const roundUpToStep = (value: number, isFromInput?: boolean): number => {
  // always round up to step when from buttons, round accordingly when from input
  const roundedValue: number = isFromInput
    ? Math.round(value / props.step) * props.step
    : Math.ceil(value / props.step) * props.step;

  if (roundedValue > props.maxValue) {
    if (isFromInput) {
      roundUpToMaxQuantityNotification();
    }

    return Math.floor(props.maxValue / props.step) * props.step;
  }

  if (isFromInput && value !== roundedValue && value <= props.maxValue) {
    roundToStepNotification();
  }

  return roundedValue;
};

const roundDownToStep = (value: number): number => {
  return Math.floor(value / props.step) * props.step;
};

const roundUpToMinQuantity = (minOrderQty: number) => {
  notificationsStore.addNotification({
    notificationItem: {
      Title: 'Quantity adjusted',
      Description: 'Minimum order quantity of product is ' + minOrderQty,
      Type: NotificationTypes.Information,
      Timing: 7000,
    },
    notificationId: 'minOrderQuantity' + minOrderQty,
  });
};

const roundUpToMaxQuantityNotification = () => {
  let pageType = 'cart';
  if (pageName.value === 'list' || pageName.value === 'quote') {
    pageType = pageName.value;
  } else if (props.isQuoteModal) {
    pageType = 'quote';
  }

  const description = `You can only add ${props.maxValue} items to your ${pageType}`;

  notificationsStore.addNotification({
    notificationItem: {
      Title: 'Max. quantity',
      Description: description,
      Type: NotificationTypes.Information,
      Timing: 7000,
    },
    notificationId: `maxQuantity_${pageName.value}`,
  });
};

const roundToStepNotification = () => {
  notificationsStore.addNotification({
    notificationItem: {
      Title: 'Quantity adjusted',
      Description:
        'Product quantity is adjusted to reflect package or rounding quantity of ' + props.step,
      Type: NotificationTypes.Information,
      Timing: 7000,
    },
    notificationId: 'stepOrderQuantity' + props.step,
  });
};

const quantityHandler = () => {
  activeChange.value = true;

  if (props.quoteItem) {
    quotesStore.selectOrderLineChange({
      entryNumber: props.entry!.EntryNumber,
      quantity: parseInt(newValue.value, 10),
    });
  }

  emit('update:modelValue', +newValue.value);
  emit('change', +newValue.value);
};

const decrease = () => {
  const roundedQty = roundDownToStep(
    parseInt(newValue.value, 10) -
      (parseInt(newValue.value, 10) <= props.minValue ? props.minValue : props.step),
  );
  const qty = (qtyRequest.value = props.avoidZeroValue
    ? roundedQty >= props.minValue
      ? roundedQty
      : props.minValue
    : roundedQty);

  if (queuingAddToCart.value && qty <= 0) {
    return;
  }

  if (qty <= 0 && props.minValue > 1) {
    if (props.entry && !props.quoteItem) {
      if (!props.avoidZeroValue) {
        const listId = props.listId ? { ListId: props.listId } : {};
        const moq = { RoundedMOQ: newValue.value };
        modalStore.showModal({
          modalComponent: 'ModalConfirmEntryDelete',
          params: Object.assign({}, props.entry, listId, moq),
        });
      } else {
        adjustQuantity(props.minValue);
      }
    } else {
      adjustQuantity(qty);
    }
  } else {
    adjustQuantity(qty);
    const cartData = productStore.productById(props.productId!);
    const cartEntry = cartStore.getCartEntry(props.productId!);

    if (cartEntry) {
      if (cartData) {
        TrackingCache.setOwningSystem(cartData.OwningSystem);
      }

      TrackingCache.setProductId(props.productId!);

      if (props.modelValue && +props.modelValue === 1) {
        if (!route.fullPath.includes('lists')) {
          AdobeLaunchTracking.removeFromCart(cartEntry);
        }
      } else {
        const quoteRoute = route.fullPath;
        if (props.isRequestQuote || quoteRoute.includes('quotes')) {
          return;
        }
      }
    }
  }

  SearchEventBus.$emit('decrease');
};

const increase = () => {
  const qty = roundUpToStep(parseInt(newValue.value, 10) + 1);

  adjustQuantity(qty);

  const cartData = productStore.productById(props.productId!);
  if (props.modelValue && +props.modelValue === 0) {
    if (cartData) {
      AdobeLaunchTracking.addToCart(cartData);
    }
  } else {
    const quoteRoute = route.fullPath;

    if (props.isRequestQuote || quoteRoute.includes('quote')) {
      return;
    }

    if (cartData) {
      TrackingCache.setOwningSystem(cartData?.OwningSystem);
      TrackingCache.setProductId(props.productId!);
    }
  }

  SearchEventBus.$emit('increase');
};

const blurInput = () => {
  inputfield.value?.blur();
};

const onBlur = () => {
  emit('blur');
};

const onFocus = () => {
  emit('focus');
};

const setSelect = () => {
  inputfield.value?.focus();
  inputfield.value?.select();
};

const openQuoteModal = () => {
  if (props.isAirbus && props.procurementType === ProcurementType.New) {
    openModal({
      modalComponent: 'ModalCreateAirbusQuote',
      params: { id: props.productId },
    });
    return;
  }

  openModal({
    modalComponent: 'ModalCreateSingleLineQuote',
    params: { id: props.productId },
  });
};

watch(
  () => props.modelValue,
  () => {
    if (parseInt(newValue.value, 10) === parseInt('' + props.modelValue, 10)) {
      return;
    }
    newValue.value = props.modelValue.toString();
  },
);

const adjustQuantityFromInput = debounce(async (newVal: string) => {
  const empty: boolean = newVal === '';
  const current: boolean = parseInt(newVal, 10) === parseInt('' + props.modelValue, 10);

  if (empty || current || (props.quoteItem && !props.isAirbusQuote)) {
    return;
  }

  if (props.withDelay) {
    await delay(2000);
  }

  const qty = roundUpToStep(parseInt(newVal, 10), true);

  if (qty > props.maxValue) {
    roundToStepNotification();
  }

  adjustQuantity(qty);
}, 1000);

watch(
  () => newValue.value,
  (newVal) => {
    adjustQuantityFromInput(newVal);
  },
);

watch(
  () => props.minValue,
  () => {
    emit('change', newValue.value);
  },
);

onMounted(() => {
  newValue.value = props.modelValue.toString();
});

defineExpose({
  activeChange,
  setSelect,
});
</script>

<template>
  <div class="accordion-item" :class="{ 'accordion-item--selected': open }">
    <div class="accordion-item-header" @click="toggle()">
      <div class="accordion-item-header-icon">
        <PlusMinusAnimation
          class="accordion-item-header-icon__icon"
          :open="open"
        ></PlusMinusAnimation>
      </div>

      <div class="accordion-item-header-info">
        <div class="accordion-item-header-title">{{ title }}</div>
        <div class="accordion-item-header-description" v-if="description">
          {{ description }}
        </div>
      </div>
    </div>
    <TransitionExpand>
      <div class="accordion-item-wrapper" v-if="open">
        <div class="accordion-item-inner typeset">
          <slot></slot>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<style lang="scss" src="./accordion-item.scss" scoped></style>

<script lang="ts" setup>
import PlusMinusAnimation from '@/src/core/components/ui/ani-icons/plus-minus/plus-minus.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';

import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface AccordionItemProps {
  title: string;
  description?: string;
  index: number;
  isOpen?: boolean;
}
const props = withDefaults(defineProps<AccordionItemProps>(), {
  isOpen: false,
  description: '',
});
const open = ref(props.isOpen);
const router = useRouter();
const currentRouteName = computed(() => router.currentRoute.value.name);
const toggle = () => {
  open.value = !open.value;
};

watch(open, (value) => {
  if (value) {
    AdobeLaunchTracking.interaction({
      action: 'accordion click',
      label: props.title,
      component: 'accordion',
      location: currentRouteName.value ?? '',
      value: '',
    });
  }
});
</script>

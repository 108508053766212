import { AxiosResponse } from 'axios';
import { UmbracoApi } from '@/src/core/api';
import { HttpResponse } from '@/src/core/apim';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { getStatus } from '@/src/core/services/axios-utils';

export type ResponseWithRetryAttributes<T> = (
  | Promise<AxiosResponse<any>>
  | Promise<HttpResponse<T>>
) & {
  shouldLogOut?: boolean;
  retryRequest?: () => Promise<ResponseWithRetryAttributes<T>>;
};

// Request interceptor
// Check if we need to refresh the access token
// if the access token is expired
export const generateRefreshTokenValidator = () => {
  const authenticationStore = useAuthenticationStore();
  let isAccessTokenRefreshing = false;

  const validateAndRefreshAccessToken = async (url?: string) => {
    const blacklistedUrls = [UmbracoApi.getRefreshToken, UmbracoApi.apimLogin];
    const tokenInvalid =
      authenticationStore.isAccessTokenExpired() && authenticationStore.isAuthenticated;

    if (!isAccessTokenRefreshing && tokenInvalid && !blacklistedUrls.includes(url ?? '')) {
      isAccessTokenRefreshing = true;
      try {
        await authenticationStore.refreshAccessToken();
      } catch (error) {
        console.error(error);
      } finally {
        isAccessTokenRefreshing = false;
      }
    }
  };

  return validateAndRefreshAccessToken;
};

// Response interceptor
// Check if the response status is 401 and the user is authenticated
// try to refresh the access token and retry the request
// log out the user if the second attempt fails
// mostly needed for .NET responses
// that can contain multiple SAPCC calls
export const generateRefreshTokenInterceptor = () => {
  let refreshTokenPromise: Promise<void> | null = null;
  const clearRefreshTokenPromise = () => (refreshTokenPromise = null);
  const authenticationStore = useAuthenticationStore();

  const interceptAndRefreshToken = async <T>(
    error: AxiosResponse<any> | HttpResponse<T>,
    resp: ResponseWithRetryAttributes<T>,
  ) => {
    const status = getStatus(error);

    if (authenticationStore.isAuthenticated && status === 401) {
      if (resp.shouldLogOut) {
        authenticationStore.doLogout();
        return error;
      } else {
        resp.shouldLogOut = true;

        if (!refreshTokenPromise) {
          refreshTokenPromise = authenticationStore
            .refreshAccessToken()
            .finally(clearRefreshTokenPromise);
        }

        await refreshTokenPromise;

        return resp.retryRequest && resp.retryRequest();
      }
    }

    return error;
  };

  return interceptAndRefreshToken;
};

import { HttpResponse } from '@/src/core/apim';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * Checks if the given response is an AxiosError.
 *
 * @param resp - The response object to check.
 * @returns True if the response is an AxiosError, false otherwise.
 */
export const isAxiosError = (resp: any): resp is AxiosError => {
  return (resp as AxiosError).response !== undefined;
};

/**
 * Retrieves the status code from the given response object.
 *
 * @template T - The type of the response data.
 * @param {AxiosResponse<any> | HttpResponse<T>} resp - The response object. Can be either Axios or HttpResponse.
 * @returns {number} - The status code of the response.
 */
export const getStatus = <T>(resp: AxiosResponse<any> | HttpResponse<T>): number => {
  if (isAxiosError(resp)) {
    return resp.response?.status || 404;
  } else {
    return resp.status;
  }
};

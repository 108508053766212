import { UmbracoApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMaintenanceStore = defineStore('maintenance', () => {
  const maintenanceMode = ref<boolean>(false);

  const reset = () => {
    maintenanceMode.value = false;
  };

  const maintenanceModeUpdated = (payload: boolean) => {
    maintenanceMode.value = payload;
  };

  const fetchMaintenanceModeStatus = async () => {
    const { IsSuccess, Data } = await Req({ url: UmbracoApi.getMaintenanceStatus });
    if (IsSuccess) {
      maintenanceModeUpdated(Data);
    }
  };

  return {
    fetchMaintenanceModeStatus,
    reset,
    maintenanceMode,
  };
});

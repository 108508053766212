<template>
  <div class="documents-filters" :class="{ 'documents-filters--modal': modal }">
    <div class="documents-filters__group">
      <div class="documents-filters__group-name">
        {{ textK('UI_DOCUMENTS_PERIOD_TITLE') }}
      </div>
      <VSelect
        class="documents-filters__group-input"
        :label="textK('UI_DOCUMENTS_PERIOD_SELECT_LABEL')"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        :options="periodOptions"
        v-model="selectedPeriod"
      ></VSelect>
      <DatePickerWrapper
        ref="datepicker"
        class="documents-filters__group-input"
        :label="textK('UI_DOCUMENTS_PERIOD_FILTER_TITLE')"
        :fromDate="fromDate"
        :toDate="toDate"
        :minDate="dayjs().subtract(2, 'year').format(DisplayDateFormat)"
        :maxDate="dayjs().format(DisplayDateFormat)"
        :range="true"
        :multi-calendars="true"
        :auto-position="true"
        @change="pickerFromDate = $event"
        @change2="pickerToDate = $event"
        @apply="applyDates($event)"
      >
        <template #label>
          <VTextField
            class="documents-filters__group-input"
            :label="textK('UI_COMMON_PERIOD_DATEPICKER_LABEL')"
            :model-value="dates"
            :type="modal ? uiVariant.Secondary : uiVariant.Primary"
            :silent-disabled="true"
            :as-span="true"
          />
        </template>
      </DatePickerWrapper>
    </div>
    <div class="documents-filters__group">
      <div class="documents-filters__group-name">
        {{ textK('UI_COMMON_SEARCH_FILTER_TITLE') }}
        <button
          v-if="showClearSearch"
          class="documents-filters__clear-filter text-button"
          @click="resetFilters()"
        >
          {{ textK('UI_COMMON_CLEAR_SEARCH') }}
        </button>
      </div>
      <VSelect
        class="documents-filters__group-input"
        :label="textK('UI_DOCUMENTS_FILTER_TYPE_LABEL')"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        :options="typeOptions"
        v-model="selectedType"
      />
      <VTextField
        class="documents-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        :label="textK('UI_DOCUMENTS_DOC_NO')"
        v-model="invoiceNo"
      >
        <template #append v-if="!modal">
          <button
            class="documents-filters__group-input__button text-button"
            @click="
              openModal({
                modalComponent: 'ModalMultilineQuery',
                params: {
                  value: 'documentNumbers',
                  title: textK('UI_DOCUMENTS_DOC_NO'),
                },
              })
            "
          >
            <svgicon name="ui-multiline"></svgicon>
          </button>
        </template>
      </VTextField>
      <VTextField
        class="documents-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        :label="textK('UI_COMMON_PART_NO')"
        v-model="partNumbers"
      >
        <template #append v-if="!modal">
          <button
            class="documents-filters__group-input__button text-button"
            @click="
              openModal({
                modalComponent: 'ModalMultilineQuery',
                params: {
                  value: 'materialNumberExternals',
                  title: textK('UI_COMMON_PART_NO'),
                },
              })
            "
          >
            <svgicon name="ui-multiline"></svgicon>
          </button>
        </template>
      </VTextField>
      <VTextField
        class="documents-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        :label="textK('UI_COMMON_ORDER_NO')"
        v-model="orderNumbers"
      >
        <template #append v-if="!modal">
          <button
            class="documents-filters__group-input__button text-button"
            @click="
              openModal({
                modalComponent: 'ModalMultilineQuery',
                params: {
                  value: 'orderNumbers',
                  title: textK('UI_COMMON_ORDER_NO'),
                },
              })
            "
          >
            <svgicon name="ui-multiline"></svgicon>
          </button>
        </template>
      </VTextField>
      <VTextField
        class="documents-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        :label="textK('UI_COMMON_PO_NO')"
        v-model="poNumbers"
      />
    </div>
  </div>
</template>

<style src="./documents-filters.scss" lang="scss" scoped />

<script lang="ts" setup>
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { IVSelect } from '@/src/core/types/ui';
import { createDateRange } from '@/src/core/utils/datepicker';
import { DisplayDateFormat, InputDateFormat } from '@/src/core/utils/dates';
import { FilterStringArrayUtils } from '@/src/core/utils/string-array';
import { PeriodOptions } from '@/src/finance/components/utils/period-options';
import { IDocumentQuery, useDocumentsStore } from '@/src/finance/stores/documents';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { computed, ref } from 'vue';

enum DocumentTypes {
  All,
  Invoices,
  Credit,
}

interface Props {
  modal?: boolean;
}

withDefaults(defineProps<Props>(), {
  modal: false,
});

const textK = useText();
const openModal = useModal();
const documentsStore = useDocumentsStore();

const uiVariant = UiVariant;
const periodOptions = PeriodOptions;

const pickerFromDate = ref('');
const pickerToDate = ref('');
const query = ref<IDocumentQuery>({});
const datepicker = ref<typeof DatePickerWrapper | null>(null);
const typeOptions: IVSelect[] = [
  { label: 'All', value: 'all' },
  { label: 'Invoices', value: 'invoices' },
  { label: 'Credit note', value: 'credit' },
];

const selectedType = computed({
  get: () => {
    if (documentsStore.filters.excludeCredits) {
      return DocumentTypes.Invoices;
    } else if (documentsStore.filters.excludeInvoices) {
      return DocumentTypes.Credit;
    } else {
      return DocumentTypes.All;
    }
  },
  set: (value) => {
    switch (value) {
      case DocumentTypes.All:
        documentsStore.setFilters({
          excludeCredits: false,
          excludeInvoices: false,
        });
        break;

      case DocumentTypes.Invoices:
        documentsStore.setFilters({
          excludeCredits: true,
          excludeInvoices: false,
        });
        break;

      case DocumentTypes.Credit:
        documentsStore.setFilters({
          excludeCredits: false,
          excludeInvoices: true,
        });
        break;
    }
  },
});

const selectedPeriod = computed({
  get: () => {
    const index: number = periodOptions.findIndex((period) => period.value === dates.value);
    return index > -1 ? index : periodOptions.length - 1;
  },
  set: (index: number) => {
    if (index >= 0) {
      if (periodOptions[index].value !== '') {
        dates.value = periodOptions[index].value;
      } else {
        setTimeout(() => {
          // TODO: after migration to new datepicker remove the timeout
          openDatepicker();
        }, 100);
      }
    }
  },
});

const openDatepicker = () => {
  datepicker.value?.openMenu();
};

const filters = computed(() => {
  return documentsStore.filters;
});

const fromDate = computed({
  get: () => {
    return filters.value.fromDate;
  },
  set: (value) => {
    documentsStore.setFilters({
      fromDate: dayjs(value, InputDateFormat).format(DisplayDateFormat),
    });
  },
});

const toDate = computed({
  get: () => {
    return filters.value.toDate;
  },
  set: (value) => {
    documentsStore.setFilters({
      toDate: dayjs(value, InputDateFormat).format(DisplayDateFormat),
    });
  },
});

const dates = createDateRange(fromDate, toDate);

const invoiceNo = computed({
  get: () => {
    return FilterStringArrayUtils.filterArrayToString(query.value.documentNumbers);
  },
  set: (value: string) => {
    Object.assign(query.value, {
      documentNumbers: FilterStringArrayUtils.stringToFilterArray(value),
    });
    setFilters();
  },
});

const poNumbers = computed({
  get: () => {
    return FilterStringArrayUtils.filterArrayToString(query.value.poNumbers);
  },
  set: (value) => {
    Object.assign(query.value, {
      poNumbers: value,
    });
    setFilters();
  },
});

const partNumbers = computed({
  get: () => {
    return FilterStringArrayUtils.filterArrayToString(query.value.materialNumberExternals);
  },
  set: (value) => {
    Object.assign(query.value, {
      materialNumberExternals: FilterStringArrayUtils.stringToFilterArray(value),
    });
    setFilters();
  },
});

const orderNumbers = computed({
  get: () => {
    return FilterStringArrayUtils.filterArrayToString(query.value.orderNumbers);
  },
  set: (value) => {
    Object.assign(query.value, { orderNumbers: FilterStringArrayUtils.stringToFilterArray(value) });
    setFilters();
  },
});

const showClearSearch = computed(() => {
  return (
    selectedType.value !== 0 ||
    invoiceNo.value ||
    poNumbers.value ||
    partNumbers.value ||
    orderNumbers.value
  );
});

const setFilters = debounce(() => {
  documentsStore.setFilters(query.value);
}, 500);

const applyDates = (newDates: string) => {
  dates.value = newDates;
};

const resetFilters = () => {
  query.value = Object.assign({});
  documentsStore.resetFilters();
};
</script>

<template>
  <StandardDialogModal class="list-modal" :size="uiSize.Medium" :loading="loading">
    <template #header>
      <h3>{{ textK('UI_ORDERS_DOCUMENTS') }}</h3>
      <p class="download-documets-number">
        PO no. {{ order.PurchaseOrderNumber }}
        <span class="download-documets-number__po">/ Order no. {{ order.FormattedCode }}</span>
      </p>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <div v-if="!loading">
            <!-- Satair & marketplace documents  -->
            <template v-if="documents && documents.length > 0">
              <DownloadDocumentList
                v-for="(group, i) in documents"
                :key="i + 'a'"
                :title="group[0].DocumentCategory"
              >
                <DownloadDocumentListItem
                  v-for="(document, index) in group"
                  :key="index + 'b'"
                  :title="document.DocumentNumber"
                  :subtitle="document.Description"
                  :is-loading="checkDownloadPending(document.DocumentUrl)"
                  :on-click="() => download(document.DocumentUrl, document)"
                />
              </DownloadDocumentList>
            </template>
            <!-- Airbus customs invoice  -->
            <DownloadDocumentList
              v-if="hasAirbusCustomsInvoice && !hasAirbusInvoices"
              :title="'Customs invoice'"
            >
              <DownloadDocumentListItem
                :title="'Customs invoice'"
                :subtitle="'Customs invoice'"
                :is-loading="checkDownloadPending(customsInvoiceUrl)"
                :on-click="() => downloadCustomsInvoice()"
              />
            </DownloadDocumentList>
            <!-- Airbus invoices  -->
            <DownloadDocumentList v-if="hasAirbusInvoices" :title="'Invoice'">
              <DownloadDocumentListItem
                v-for="(invoice, i) in airbusInvoices"
                :key="i"
                :title="invoice"
                :subtitle="'Invoice'"
                :is-loading="checkDownloadPending(invoice)"
                :on-click="() => downloadAirbusInvoice(invoice)"
              />
            </DownloadDocumentList>
            <!-- Airbus certificates  -->
            <DownloadDocumentList v-if="hasAirbusCertificates" :title="'Certificate'">
              <DownloadDocumentListItem
                v-for="(certificate, i) in airbusCertificates"
                :key="i"
                :title="certificate"
                :subtitle="'Certificate'"
                :is-loading="checkDownloadPending(certificateUrl(certificate))"
                :on-click="() => downloadAirbusCertificate(certificate)"
              />
            </DownloadDocumentList>
            <div v-if="!hasDocuments">
              {{ textK('UI_ORDERS_NO_DOCUMENTS') }}
            </div>
          </div>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
    <template #actions v-if="canDownloadAll">
      <VButton
        v-ui-test="'order-download-all'"
        :type="uiVariant.Default"
        :loading="checkDownloadPending(downloadAllUrl)"
        @click="downloadAll"
        >{{ textK('UI_ORDERS_DOWNLOADALL') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-download-documents.scss" scoped></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import { OrderApi } from '@/src/core/api';
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import SearchList from '@/src/core/components/ui/search-list/search-list.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { removeDuplicates } from '@/src/core/services/tracking-utils-service';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { useModalStore } from '@/src/core/stores/modal';
import { OrderDocument } from '@/src/core/types/api';
import DownloadDocumentListItem from '@/src/market/components/download-document-list-item/download-document-list-item.vue';
import DownloadDocumentList from '@/src/market/components/download-document-list/download-document-list.vue';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { useOrdersStore } from '@/src/market/stores/orders';

@Component({
  components: {
    DownloadDocumentList,
    DownloadDocumentListItem,
    DotLoader,
    TransitionExpand,
    StandardDialogModal,
    ModalColumnContainer,
    ModalColumn,
    SimpleListItem,
    SearchList,
    VButton,
  },
})
export class ModalDownloadDocuments extends Vue {
  public textK = useText();
  public downloadsStore = useDownloadsStore();
  public modalStore = useModalStore();
  public orderDetailsStore = useOrderDetailsStore();
  public ordersStore = useOrdersStore();
  public uiSize = UiSize;
  public uiVariant = UiVariant;

  private get orderCode(): string {
    return this.modalStore.params as string;
  }

  public get documents() {
    return this.orderDetailsStore.order.DocumentsGrouped;
  }

  public get airbusCertificates() {
    return this.orderDetailsStore.airbusBoxUnitId;
  }

  public get airbusInvoices() {
    return this.orderDetailsStore.airbusInvoiceNumbers;
  }

  public get hasAirbusCertificates() {
    return Boolean(this.airbusCertificates?.length);
  }

  public get hasAirbusInvoices() {
    return Boolean(this.airbusInvoices?.length);
  }

  public get hasAirbusCustomsInvoice() {
    return Boolean(this.orderDetailsStore.hasCustomsInvoice);
  }

  public get hasDocuments() {
    const allDocuments = [
      this.documents,
      this.hasAirbusCertificates,
      this.hasAirbusInvoices,
      this.hasAirbusCustomsInvoice,
    ];

    return allDocuments.some(Boolean);
  }

  public get canDownloadAll() {
    return (
      this.documents &&
      this.documents.length > 0 &&
      this.enableDocumentDownload &&
      this.order.IsSatairOrder
    );
  }

  public get order() {
    return this.orderDetailsStore.order;
  }

  public get downloadAllUrl() {
    return OrderApi.DownloadAllDocuments(this.orderCode);
  }

  private get isCurrentOrder() {
    return this.order.Code === this.orderCode;
  }

  public get customsInvoiceUrl() {
    const orderCode = this.orderCode;
    return OrderApi.DownloadCustomsInvoice(orderCode);
  }

  public certificateUrl(boxUnitId: string) {
    return OrderApi.DownloadAirbusOrderCertificate(boxUnitId);
  }

  public checkDownloadPending(url: string): boolean {
    return this.downloadsStore.isDownloading(url);
  }

  public download(url: string, document: OrderDocument | { [key: string]: string }) {
    AdobeLaunchTracking.pdfDownloadTracking({
      fileName: document.DocumentNumber || Object.values(document)[0],
      fileCategory: document.DocumentCategory || Object.keys(document)[0].replace(/_/gi, ' '),
    });
    this.downloadsStore.downloadFile({ url });
  }

  public downloadAirbusInvoice(invoice: string) {
    this.downloadsStore.downloadFile(
      {
        url: OrderApi.DownloadAirbusOrderInvoice,
        method: 'POST',
        data: {
          documentIds: [invoice],
        },
      },
      undefined,
      OrderApi.HandleInvoiceDownloads,
    );
  }

  public downloadAirbusCertificate(certificate: string) {
    AdobeLaunchTracking.pdfDownloadTracking({
      fileName: certificate,
      fileCategory: certificate,
    });
    this.downloadsStore.downloadFile(
      { url: this.certificateUrl(certificate) },
      undefined,
      OrderApi.HandleDownloadAirbusCertificate.bind(OrderApi, {
        certificate,
      }),
    );
  }

  public downloadCustomsInvoice() {
    AdobeLaunchTracking.pdfDownloadTracking({
      fileName: 'customs invoice',
      fileCategory: 'customs invoice',
    });
    this.downloadsStore.downloadFile(
      { url: this.customsInvoiceUrl },
      undefined,
      OrderApi.HandleCustomsInvoice.bind(OrderApi, {
        orderCode: this.orderCode,
      }),
    );
  }

  public downloadAll() {
    const mappedCategories = this.documents.flatMap((doc) =>
      doc.flatMap((item) => item.DocumentCategory),
    );
    AdobeLaunchTracking.pdfDownloadTracking({
      fileName: 'Multiple files - Bulk download',
      fileCategory: removeDuplicates(mappedCategories),
    });
    this.downloadsStore.downloadFile(
      { url: this.downloadAllUrl },
      undefined,
      OrderApi.HandleDownloadAllDocuments.bind(OrderApi, {
        orderCode: this.orderCode,
      }),
    );
  }

  public get loading() {
    return this.ordersStore.isBusy || this.orderDetailsStore.isBusy;
  }

  public get enableDocumentDownload(): boolean {
    return hasFeature(FEATURES.DOCUMENT_DOWNLOAD);
  }

  public created() {
    if (!this.isCurrentOrder) {
      this.orderDetailsStore.fetchOrderDetails({
        orderCode: this.orderCode,
      });
    }
  }
}

export default toNative(ModalDownloadDocuments);
</script>

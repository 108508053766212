import { ListApi } from '@/src/core/api';
import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { ListType, OwningSystem } from '@/src/core/types/api';
import {
  IList,
  IListEntry,
  IListFilterOptions,
  IListImportPreview,
  IListPagingOverview,
} from '@/src/core/types/interfaces';
import { AssistantStates } from '@/src/core/types/ui';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useProductStore } from '../stores/product';
import { useProductAdditionalInfoStore } from '../stores/product-additional-info';
import { useProductPriceInfoStore } from '../stores/product-price-info';
import { useUserStore } from '@/src/profile/stores/user';

const emptyList: IList = {
  Entries: [] as IListEntry[],
  FilterOptions: {
    SearchQuery: '',
    IsCollapsed: true,
  } as IListFilterOptions,
} as IList;

export const useListsStore = defineStore('lists', () => {
  const assistantStore = useAssistantStore();
  const productStore = useProductStore();
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const productPriceInfoStore = useProductPriceInfoStore();
  const airbusPartStore = useAirbusPartStore();
  const userStore = useUserStore();

  const count = ref<number>(0);
  const page = ref<number>(0);
  const totalPages = ref<number>(0);
  const totalCount = ref<number>(0);
  const rawCurrentList = ref<IList>(emptyList);
  const currentListActiveProductIndex = ref<number>(-1);
  const allLists = ref<IList[]>([]);
  const recentlyUsedLists = ref<IList[]>([]);
  const importPreview = ref<IListImportPreview | undefined>(undefined);
  const isBusy = ref<boolean>(false);
  const sortOrder = ref<string>('modifiedTime-desc');

  const currentList = computed(() => {
    const list = JSON.parse(JSON.stringify(rawCurrentList.value)) as IList;
    list.Entries.forEach((entry) => {
      if (entry.Id) {
        const product = productStore.productById(entry.Id);

        if (product) {
          entry.Product = product;
        }
      }
    });
    return list;
  });

  const filterOptions = computed(() => {
    return currentList.value.FilterOptions;
  });

  const currentListUpdated = (payload: { list: IList }) => {
    rawCurrentList.value = Object.assign({}, rawCurrentList.value, payload.list);
  };

  const currentListProductUpdated = (payload: { entry: IListEntry; id: string }) => {
    const entryIndex: number = rawCurrentList.value.Entries.findIndex(
      (entry) => entry.Id === payload.id,
    );

    if (payload.entry) {
      rawCurrentList.value.Entries[entryIndex].Quantity = payload.entry.Quantity;
    } else {
      rawCurrentList.value.Entries.splice(entryIndex, 1);
    }
  };

  const currentListActiveProductIndexUpdated = (payload: { index: number }) => {
    currentListActiveProductIndex.value = payload.index;
  };

  const allListsUpdated = (payload: {
    AllLists: IListPagingOverview;
    loadMore?: boolean;
    ignorePaging?: boolean;
  }) => {
    if (!payload.loadMore) {
      allLists.value = [];
    }

    allLists.value.push(...payload.AllLists.ProductLists);
    const selectedSortingObj = payload.AllLists.Sorts.find((key) => key.Selected);
    if (selectedSortingObj !== undefined) {
      sortOrder.value = selectedSortingObj.Code;
    }

    if (!payload.ignorePaging) {
      page.value = payload.AllLists.Page;
    }

    totalPages.value = payload.AllLists.TotalPages;
    totalCount.value = payload.AllLists.Count;
  };

  const recentListsUpdated = (payload: { RecentLists: IList[]; Count: number }) => {
    recentlyUsedLists.value = [];
    recentlyUsedLists.value = payload.RecentLists;
    totalCount.value = payload.Count;
  };

  const importPreviewUpdated = (payload: {
    ImportPreview: IListImportPreview;
    Filename: string;
  }) => {
    importPreview.value = Object.assign({ Filename: '' }, payload.ImportPreview, {
      Filename: payload.Filename.substring(0, payload.Filename.lastIndexOf('.')),
    });
  };

  const unresolvedCleared = () => {
    rawCurrentList.value.UnresolvedEntries = [];
  };

  const unresolvedItemCleared = (payload: { pno: string; cageCode: string }) => {
    if (payload.cageCode) {
      rawCurrentList.value.UnresolvedEntries = rawCurrentList.value.UnresolvedEntries.filter(
        (item) => {
          return item.PartNumber !== payload.pno || item.CageCode !== payload.cageCode;
        },
      );
    } else {
      rawCurrentList.value.UnresolvedEntries = rawCurrentList.value.UnresolvedEntries.filter(
        (item) => {
          return item.PartNumber !== payload.pno;
        },
      );
    }
  };

  const importMappingUpdated = (payload: { headerIndex: string; columnIndex: number }) => {
    if (importPreview.value && importPreview.value.Mapping) {
      if (payload.headerIndex) {
        const currentMapping = importPreview.value.HeaderMappings.find(
          (x) => x.IdxName === payload.headerIndex,
        );

        importPreview.value.HeaderMappings.map((x) => {
          if (x.Value === payload.columnIndex) {
            x.Value = -1;
          }
        });

        if (currentMapping) {
          if (currentMapping.IdxName === 'SkuIdx') {
            importPreview.value.HeaderMappings.map((x) => {
              if (x.IdxName === 'PnoIdx') {
                x.Value = -1;
              } else if (x.IdxName === 'ManuIdx') {
                x.Value = -1;
              }
            });
          }

          if (currentMapping.IdxName === 'PnoIdx' || currentMapping.IdxName === 'ManuIdx') {
            importPreview.value.HeaderMappings.map((x) => {
              if (x.IdxName === 'SkuIdx') {
                x.Value = -1;
              }
            });
          }

          currentMapping.Value = payload.columnIndex;
        }
      }
    }
  };

  const searchQueryUpdated = (payload: { SearchQuery: string }) => {
    rawCurrentList.value.FilterOptions.SearchQuery = payload.SearchQuery;
  };

  const isCollapsedUpdated = (payload: { isCollapsed: boolean }) => {
    rawCurrentList.value.FilterOptions.IsCollapsed = payload.isCollapsed;
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const resetFilters = () => {
    rawCurrentList.value.FilterOptions = {
      SearchQuery: '',
      IsCollapsed: true,
    };
  };

  const resetCurrentList = () => {
    rawCurrentList.value = Object.assign({}, emptyList);
  };

  const reset = () => {
    count.value = 0;
    page.value = 0;
    totalPages.value = 0;
    totalCount.value = 0;
    rawCurrentList.value = emptyList;
    allLists.value = [] as IList[];
    recentlyUsedLists.value = [] as IList[];
    importPreview.value = undefined;
    isBusy.value = false;
    sortOrder.value = 'modifiedTime-desc';
  };

  const fetchRecentLists = async () => {
    const facets = 'productListType:USER_CREATED';
    const paramaters = {
      facets,
      size: 6,
    };
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess, Data } = await Req(
      {
        url: ListApi.RecentLists,
        params: paramaters,
      },
      undefined,
      ListApi.handleAllLists,
    );

    if (IsSuccess && Data) {
      recentListsUpdated({ RecentLists: Data.RecentProductLists, Count: Data.Count });
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess, Data };
  };

  const fetchAllLists = async (payload?: {
    loadMore?: boolean;
    updateCurrent?: boolean;
    sortOrder?: string;
  }) => {
    const facets = 'productListType:USER_CREATED';
    isBusyUpdated({ IsBusy: true });
    const itemsPrReq = 20;
    const paramaters = {
      facets,
      page: 0,
      size: itemsPrReq,
      sort: payload?.sortOrder || sortOrder.value,
    };

    if (payload?.updateCurrent) {
      paramaters.size = (page.value + 1) * itemsPrReq;
    } else if (payload?.loadMore) {
      const pageNumber = page.value + 1;

      if (pageNumber === totalPages.value) {
        return;
      }
      paramaters.page = pageNumber;
    }

    const { IsSuccess, Data } = await Req(
      {
        url: ListApi.AllLists,
        params: paramaters,
      },
      new ReqQueue(ReqQueueTypes.Default, 'Lists'),
      ListApi.handleAllLists,
    );

    if (IsSuccess && Data) {
      allListsUpdated({
        AllLists: Data,
        loadMore: payload?.loadMore,
        ignorePaging: payload?.updateCurrent,
      });
    }
    isBusyUpdated({ IsBusy: false });
  };

  const cancelFetchAllList = async () => {
    CancelReqCategory('Lists');
  };

  const updateSearchQuery = async (payload: { query: string }) => {
    searchQueryUpdated({ SearchQuery: payload.query });
  };

  const updateIsCollapsed = (payload: { isCollapsed: boolean }) => {
    isCollapsedUpdated(payload);
  };

  const toggleSorting = (payload: { sortOrder: string }) => {
    let newSortOrder = payload.sortOrder;

    if (isCurrentSorting(newSortOrder)) {
      const currentDirection: string = sortOrder.value.split('-')[1];
      const reversedDirection: string = currentDirection === 'asc' ? 'desc' : 'asc';
      newSortOrder = newSortOrder + '-' + reversedDirection;
    } else {
      const currentDirection: string = sortOrder.value.split('-')[1];
      newSortOrder = newSortOrder + '-' + currentDirection;
    }
    fetchAllLists({ loadMore: false, updateCurrent: true, sortOrder: newSortOrder });
  };

  const clearFilters = () => {
    resetFilters();
  };

  const createList = async (payload: { listName: string }) => {
    isBusyUpdated({ IsBusy: true });
    const productListType = 'USER_CREATED';

    const { IsSuccess, Data } = await Req(
      {
        url: ListApi.CreateList,
        params: { listName: payload.listName, type: ListType.List, productListType },
      },
      undefined,
      ListApi.handleCreateList,
    );

    if (IsSuccess) {
      fetchAllLists({ loadMore: false, updateCurrent: true });
      fetchRecentLists();
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess, Data };
  };

  const deleteList = async (payload: { listCode: string }) => {
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess } = await Req(
      {
        url: ListApi.DeleteList(payload.listCode),
        params: { showNotification: true },
      },
      undefined,
      ListApi.handleDeleteList.bind(ListApi, payload.listCode),
    );

    if (IsSuccess) {
      fetchAllLists({ loadMore: false, updateCurrent: true });
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess };
  };

  const renameList = async (payload: { listCode: string; listName: string }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess } = await Req(
      {
        url: ListApi.RenameList(payload.listCode, payload.listName, ListType.List),
      },
      undefined,
      ListApi.handleRenameList.bind(ListApi, {
        listCode: payload.listCode,
        listName: payload.listName,
      }),
    );

    if (IsSuccess) {
      fetchAllLists({ loadMore: false, updateCurrent: true });
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess };
  };

  const saveAsList = async (payload: { listName: string; cartCode: string }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: ListApi.SaveAsList,
        params: { listName: payload.listName, cartCode: payload.cartCode },
      },
      undefined,
      ListApi.handleSaveAsList,
    );

    if (IsSuccess) {
      fetchAllLists({ loadMore: false, updateCurrent: true });
    }

    isBusyUpdated({ IsBusy: false });
    return { IsSuccess, Data };
  };

  const addListToCart = async (payload: {
    listCode: string;
    cartCode: string;
    isMultiline?: boolean;
  }) => {
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess, Data } = await Req(
      {
        url: ListApi.AddListToCart(payload.listCode, payload.cartCode),
        params: {
          isMultiline: payload.isMultiline,
        },
      },
      undefined,
      ListApi.handleAddListToCart.bind(ListApi, {
        listCode: payload.listCode,
        cartCode: payload.cartCode,
      }),
    );

    if (IsSuccess && Data) {
      if (payload.isMultiline && Data.FailCount < 1) {
        deleteList({ listCode: payload.listCode });
      }
      isBusyUpdated({ IsBusy: false });
    }
    return { IsSuccess, Data };
  };

  const getCurrentList = async (payload: {
    listCode: string;
    fetchOnly?: boolean; // will not update the view, only create quote in background
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { listCode, fetchOnly } = payload;
    const multilineUrl = {
      multiline: ListApi.MultiLineOrderList('Multiline search'),
      multilinequote: ListApi.MultiLineOrderList('Create quote'),
    }[listCode];

    const multilineApimUrl = {
      multiline: ListApi.handleMultiLineOrderList.bind(ListApi, { listName: 'Multiline search' }),
      multilinequote: ListApi.handleMultiLineOrderList.bind(ListApi, { listName: 'Create quote' }),
    }[listCode];

    const apimRequest = multilineUrl
      ? multilineApimUrl
      : ListApi.handleGetList.bind(ListApi, listCode);

    const { IsSuccess, Data } = await Req(
      {
        url: multilineUrl ?? ListApi.GetList(listCode),
      },
      undefined,
      apimRequest,
    );

    if (IsSuccess && !fetchOnly && Data) {
      currentListActiveProductIndexUpdated({ index: -1 });
      extractAndUpdateCurrentList(Data);
    }

    isBusyUpdated({ IsBusy: false });
    return { IsSuccess, Data };
  };

  const extractAndUpdateCurrentList = (list: IList) => {
    list.Entries.forEach((entry: IListEntry) => {
      if (entry.Product) {
        entry.Sku = entry.Product.Sku;
        productStore.addProduct({ product: entry.Product });
        // @ts-ignore
        delete entry.Product;
      }
    });
    currentListUpdated({ list });
  };

  const addProductToList = async (payload: {
    listCode: string;
    productId: string;
    quantity?: number;
    notify?: boolean;
    isQuoteList?: boolean;
  }) => {
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess } = await Req(
      {
        url: ListApi.AddListEntries(payload.listCode, ''),
        params: {
          quantity: payload.quantity || 1,
          productId: encodeURIComponent(payload.productId),
        },
        data: {
          enableNotification: payload.notify || false,
          isQuoteList: payload.isQuoteList || false,
        },
        method: 'POST',
      },
      undefined,
      ListApi.handleAddListEntries.bind(ListApi, {
        listCode: payload.listCode,
        productCode: payload.productId,
        quantity: payload.quantity || 1,
        enableNotification: payload.notify || false,
        isQuoteList: payload.isQuoteList || false,
      }),
    );

    isBusyUpdated({ IsBusy: false });
    return { IsSuccess };
  };

  const updateProductQuantity = async (payload: {
    listCode: string;
    productId: string;
    quantity: number;
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: ListApi.UpdateListEntry(payload.listCode, payload.productId),
        params: { quantity: payload.quantity },
      },
      new ReqQueue(ReqQueueTypes.Default, 'list', payload.productId),
      ListApi.handleUpdateListEntry.bind(ListApi, {
        listCode: payload.listCode,
        productCode: payload.productId,
        quantity: payload.quantity,
      }),
    );

    if (IsSuccess && Data) {
      currentListProductUpdated({
        entry: Data,
        id: payload.productId,
      });
      // TODO: temp implementation we still need to figure out how to handle lists code vs id when having multiple offers

      const { Id, OwningSystem: owningSystem } = productStore.productById(payload.productId) ?? {};

      if (!Id) return;

      if (owningSystem === OwningSystem.BLUE && userStore.blueCustomer) {
        await airbusPartStore.queueAirbusParts({
          OfferId: Id,
          Quantity: payload.quantity,
        });
      } else if (userStore.redCustomer) {
        await productAdditionalInfoStore.fetchProduct({
          OfferId: Id,
          Quantity: payload.quantity,
        });
        await productPriceInfoStore.fetchProduct({
          OfferId: Id,
          Quantity: payload.quantity,
        });
      }
    }

    isBusyUpdated({ IsBusy: false });
    return { IsSuccess, currentList: rawCurrentList.value };
  };

  const deleteListEntry = async (payload: {
    listCode: string;
    productId: string;
    enableNotification?: boolean;
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess } = await Req(
      {
        url: ListApi.DeleteEntry(payload.listCode, payload.productId),
        data: { enableNotification: payload.enableNotification || false },
        method: 'POST',
      },
      undefined,
      ListApi.handleDeleteEntry.bind(ListApi, {
        listCode: payload.listCode,
        productCode: payload.productId,
        enableNotification: payload.enableNotification || false,
      }),
    );

    if (IsSuccess) {
      getCurrentList({ listCode: payload.listCode });
      fetchAllLists({ loadMore: false, updateCurrent: true });
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess };
  };

  const uploadList = async (payload: { fileData: FormData }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req({
      method: 'put',
      headers: { 'content-type': 'multipart/form-data' },
      url: ListApi.PreviewImportList,
      data: payload.fileData,
    });

    if (IsSuccess && Data) {
      const filename: '' | File | null =
        payload.fileData.get('file') && (payload.fileData.get('file') as File);
      importPreviewUpdated({
        ImportPreview: Data,
        Filename: (filename && filename.name) || '',
      });
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess, Data };
  };

  const importUploadedList = async (payload: { name: string }) => {
    isBusyUpdated({ IsBusy: true });

    if (importPreview.value) {
      importPreview.value.HeaderMappings.map((x) => {
        if (importPreview.value && x && x.Value !== undefined) {
          importPreview.value.Mapping[x.IdxName] = x.Value;
        }
      });

      const { IsSuccess, Data } = await Req({
        url: ListApi.ImportList,
        data: {
          data: importPreview.value.Data,
          mapping: importPreview.value.Mapping,
          name: payload.name,
        },
        method: 'POST',
      });

      if (IsSuccess) {
        assistantStore.closeAssistant();
        fetchAllLists({ loadMore: false, updateCurrent: true });
      }

      isBusyUpdated({ IsBusy: false });
      return { IsSuccess, Data };
    } else {
      isBusyUpdated({ IsBusy: false });
      return { IsSuccess: false };
    }
  };

  const importFromRaw = async (payload: { partnumbers: string; listCode: string }) => {
    assistantStore.showAssistant({
      assistantState: AssistantStates.FullScreen,
      assistantComponent: 'UploadHandler',
      params: { loading: true },
      isLocked: true,
    });
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        method: 'post',
        url: ListApi.MultiLineOrderPasteRaw(payload.listCode),
        data: { rawtext: payload.partnumbers },
      },
      undefined,
      ListApi.handleMultiLineOrderPasteRaw.bind(ListApi, {
        listCode: payload.listCode,
        rawText: payload.partnumbers,
      }),
    );

    if (IsSuccess && Data) {
      extractAndUpdateCurrentList(Data);
    }
    isBusyUpdated({ IsBusy: false });
    assistantStore.closeAssistant();
    return { IsSuccess, Data };
  };

  const updateImportMapping = (payload: { headerIndex: string; columnIndex: number }) => {
    importMappingUpdated(payload);
  };

  const selectSuggestedItem = async (payload: {
    listCode: string;
    productId: string;
    qty: number;
    pno: string;
    cageCode?: string;
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess } = await Req(
      {
        url: ListApi.SelectProductSuggestion(
          payload.listCode,
          payload.productId,
          payload.qty,
          payload.pno,
          payload.cageCode || '',
        ),
      },
      undefined,
      ListApi.handleSelectProductSuggestion.bind(ListApi, {
        listCode: payload.listCode,
        productCode: payload.productId,
        quantity: payload.qty,
        partNumber: payload.pno,
        cageCode: payload.cageCode,
      }),
    );

    if (IsSuccess) {
      getCurrentList({ listCode: payload.listCode });
    } else {
      // IsBusy False i set in this.getCurrentList;
      isBusyUpdated({ IsBusy: true });
    }
  };

  const clearUnresolved = async (payload: { listCode: string }) => {
    const { IsSuccess } = await Req(
      {
        url: ListApi.ClearUnresolvedEntries(payload.listCode),
      },
      undefined,
      ListApi.handleClearUnresolvedEntries.bind(ListApi, { listCode: payload.listCode }),
    );

    if (IsSuccess) {
      unresolvedCleared();
      ResizeEventBus.$emit('resize');
    }
    unresolvedCleared();
    return { IsSuccess };
  };

  const clearUnresolvedItem = async (payload: {
    listCode: string;
    pno: string;
    cageCode: string;
  }) => {
    const { IsSuccess } = await Req(
      {
        url: ListApi.ClearUnresolvedEntry(
          payload.listCode,
          encodeURIComponent(payload.pno),
          encodeURIComponent(payload.cageCode) || '',
        ),
      },
      undefined,
      ListApi.handleClearUnresolvedEntry.bind(ListApi, {
        listCode: payload.listCode,
        partNumber: payload.pno,
        cageCode: payload.cageCode,
      }),
    );

    if (IsSuccess) {
      unresolvedItemCleared(payload);
    }
  };

  const setActiveProductIndex = (payload: { index: number }) => {
    if (currentListActiveProductIndex.value !== payload.index) {
      currentListActiveProductIndexUpdated({ index: payload.index });
    }
  };

  const clearCurrentList = async () => {
    resetCurrentList();
    isBusyUpdated({ IsBusy: false });
    CancelReqCategory('ListDetails');
  };

  const isCurrentSorting = (sortString: string) => {
    return sortString === sortOrder.value.split('-')[0];
  };

  const sortingDirection = (sortString: string) => {
    if (isCurrentSorting(sortString)) {
      return sortOrder.value.split('-')[1];
    } else {
      return 'desc';
    }
  };

  const getListEntry = (productId: string) => {
    return currentList.value.Entries?.find((product: IListEntry) => product.Id === productId);
  };

  return {
    fetchRecentLists,
    fetchAllLists,
    cancelFetchAllList,
    updateSearchQuery,
    updateIsCollapsed,
    toggleSorting,
    deleteList,
    renameList,
    saveAsList,
    addListToCart,
    addProductToList,
    updateProductQuantity,
    deleteListEntry,
    uploadList,
    importUploadedList,
    importFromRaw,
    updateImportMapping,
    selectSuggestedItem,
    clearUnresolved,
    clearUnresolvedItem,
    setActiveProductIndex,
    clearCurrentList,
    clearFilters,
    createList,
    reset,
    getCurrentList,
    getListEntry,
    allLists,
    recentlyUsedLists,
    currentListActiveProductIndex,
    totalCount,
    totalPages,
    page,
    isCurrentSorting,
    sortingDirection,
    currentList,
    filterOptions,
    importPreview,
    isBusy,
  };
});

<template>
  <StandardDialogModal
    class="list-modal"
    :size="uiSize.Large"
    :loading="!isFetched || changingAddress"
  >
    <template #header>
      <h3>{{ textK('UI_QUOTES_CREATE_INFORMATION_HEADER') }}</h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <AddressSelector v-model="selected" @isFetched="adressesUpdated"></AddressSelector>
        </ModalColumn>
        <ModalColumn>
          <h4>{{ textK('UI_QUOTES_ORDER_PRIORITY') }}</h4>
          <QuotePriority></QuotePriority>
        </ModalColumn>
      </ModalColumnContainer>
      <ModalColumnContainer>
        <ModalColumn class="terms">
          <h4>{{ textK('UI_QUOTES_CREATE_TERMS_AND_CONDITIONS') }}</h4>
          <Checkbox
            v-model="terms"
            :id="'terms'"
            v-ui-test="'quote-terms-and-conditions-modal-accept'"
          >
            {{ textK('UI_QUOTES_TERMS_CHECKBOX_LABEL') }}
            <VButton :size="uiSize.Small" :type="uiVariant.Link" @click.stop="termsAndConditions">{{
              textK('UI_QUOTES_CREATE_TERMS_AND_CONDITIONS').toLowerCase()
            }}</VButton>
          </Checkbox>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
    <template #actions>
      <VButton :type="uiVariant.Secondary" @click="back">{{
        textK('UI_COMMON_BACK_BUTTON')
      }}</VButton>
      <VButton :type="uiVariant.Default" :disabled="isDisabled" @click="submit">{{
        textK('UI_QUOTES_CREATE_QUOTE_BTN')
      }}</VButton>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-create-quote-select-address.scss" scoped></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import ModalAcceptTermsOfUse from '@/src/core/components/modals/modal-accept-terms-of-use/modal-accept-terms-of-use.vue';
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useModalStore } from '@/src/core/stores/modal';
import { Address, QuoteList } from '@/src/core/types/api';
import { IModalCreateQuoteParams, IQuoteSingle } from '@/src/core/types/interfaces';
import QuotePriority from '@/src/market/components/quotes/quote-list/quote-priority/quote-priority.vue';
import AddressSelector from '@/src/market/components/ui/address-selector/address-selector.vue';
import { useDeliveryAddressStore } from '@/src/market/stores/delivery-addresses';
import { useListsStore } from '@/src/market/stores/list';
import { useProductStore } from '@/src/market/stores/product';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';
import { useQuoteListStore } from '@/src/market/stores/quote-list';
import { useQuoteSingleStore } from '@/src/market/stores/quote-single';
import { useQuotesRequestStore } from '@/src/market/stores/quotes-request';

@Component({
  components: {
    VButton,
    QuotePriority,
    StandardDialogModal,
    DotLoader,
    TransitionExpand,
    ModalColumnContainer,
    ModalColumn,
    AddressSelector,
    ModalAcceptTermsOfUse,
    Checkbox,
  },
})
export class ModalCreateQuoteSelectAddress extends Vue {
  public textK = useText();
  public listsStore = useListsStore();
  public quoteDraftsStore = useQuoteDraftsStore();
  public quoteListStore = useQuoteListStore();
  public modalStore = useModalStore();
  public deliveryAddressesStore = useDeliveryAddressStore();
  public createQuoteStore = useQuotesRequestStore();
  public quoteSingleStore = useQuoteSingleStore();
  public productStore = useProductStore();

  public changingAddress: boolean = false;
  public isFetched: boolean = false;
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public terms: boolean = false;

  private get params() {
    return this.modalStore.params as IModalCreateQuoteParams;
  }

  public get id() {
    return this.params.id;
  }

  private fetchDeliveryAddress() {
    this.deliveryAddressesStore.fetchDeliveryAddresses();
  }

  public adressesUpdated() {
    if (!this.isFetched) {
      this.isFetched = true;
    }
  }

  private get isSingleLine(): boolean {
    return this.params ? this.params.single : false;
  }

  public get selected(): Address {
    const store = this.isSingleLine
      ? this.quoteSingleStore.quoteSingle
      : this.quoteListStore.quoteList;

    return store.ShippingAddressId
      ? ({ Id: store.ShippingAddressId } as Address)
      : ({ Id: this.deliveryAddressesStore.defaultDeliveryAddressId } as Address);
  }

  public set selected(item: Address) {
    this.quoteSingleStore.quoteSingleUpdated({ ShippingAddressId: item.Id } as IQuoteSingle);
    this.quoteListStore.updateQuoteProductList({ ShippingAddressId: item.Id } as QuoteList);
  }

  private setShippingAddressToDefault() {
    this.quoteSingleStore.quoteSingleUpdated({
      ShippingAddressId: this.deliveryAddressesStore.defaultDeliveryAddressId,
    } as IQuoteSingle);
  }

  public back() {
    this.isSingleLine
      ? this.modalStore.showModal({
          modalComponent: 'ModalCreateSingleLineQuote',
          params: {
            id: this.params.id,
            single: true,
            listCode: this.quoteSingleStore.quoteSingle.ListCode,
            isTerms: this.terms,
          },
        })
      : this.modalStore.showModal({
          modalComponent: 'ModalCreateQuote',
          params: {
            single: true,
            listCode: this.quoteSingleStore.quoteSingle.ListCode,
            isTerms: this.terms,
          },
        });
    this.modalStore.closeModal({ modalComponent: 'ModalCreateQuoteSelectAddress' });
  }

  public termsAndConditions() {
    this.modalStore.showModal({
      modalComponent: 'ModalQuoteTermsAndConditions',
      params: {
        id: this.params.id,
        quantity: this.params.quantity,
        single: this.params.single,
        listCode: this.params.listCode,
        isTerms: this.terms,
      },
    });
    this.modalStore.closeModal({ modalComponent: 'ModalCreateQuoteSelectAddress' });
  }

  private get store() {
    return this.isSingleLine ? this.quoteSingleStore : this.quoteListStore;
  }

  private get isBusy() {
    return this.store.isBusy;
  }

  public get isDisabled() {
    return !this.terms || this.isBusy;
  }

  public async submit() {
    if (!this.terms) {
      return;
    }

    const { listCode, single } = this.params;
    const fetchOnlyPageTypes = ['List page', 'Multiline page'];
    const pageType = this.$route?.meta?.type;
    const fetchOnly = fetchOnlyPageTypes.includes(pageType);
    const product = this.productStore.productById(this.id);
    try {
      if (this.quoteSingleStore.quoteSingle.ShippingAddressId === '') {
        this.setShippingAddressToDefault();
      }

      await this.store.populateQuoteList({ listCode, single, fetchOnly });

      const { IsSuccess } = await this.store.submitQuoteList();

      if (IsSuccess) {
        if (product) {
          AdobeLaunchTracking.quoteTracking({
            product,
            quote: { referenceNo: this.params.referenceNumber || 'Not set' },
          });
        }
        this.quoteDraftsStore.deleteList({ listCode });
        this.quoteDraftsStore.fetchAllQuoteLists();
        this.quoteSingleStore.reset();

        if (!single) {
          this.$router.push({ name: 'drafts' });
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.modalStore.closeModal();
  }

  public created() {
    this.fetchDeliveryAddress();
  }

  public async mounted() {
    if (this.params.isTerms) {
      this.terms = this.params.isTerms;
    }
  }
}

export default toNative(ModalCreateQuoteSelectAddress);
</script>

import DownloadFile from '@/src/core/services/download';
import { Req } from '@/src/core/services/requester';
import { InitialProvisioningDocument } from '@/src/core/types/api';
import { IVSelect } from '@/src/core/types/ui';
import { PlanningApi } from '@/src/planning/api/planningApi';
import { defineStore } from 'pinia';

const defaultSelectCategory: IVSelect = { value: 'All', label: 'All', hideValueWhenSelected: true };

interface State {
  documents: InitialProvisioningDocument[];
  isBusy: boolean;
  searchTerm: string;
  selectedCategory: IVSelect;
}

export const useInitialProvisioningStore = defineStore('initial-provisioning', {
  state: (): State => ({
    documents: [],
    isBusy: false,
    searchTerm: '',
    selectedCategory: defaultSelectCategory,
  }),
  getters: {
    filteredDocuments: (state) => {
      return state.documents.filter(
        ({ fileName, category }) =>
          fileName.toLowerCase().includes(state.searchTerm.toLowerCase()) &&
          (state.selectedCategory.value === 'All' ||
            category.code.includes(state.selectedCategory.value)),
      );
    },
  },
  actions: {
    async fetchInitialProvisioningDocuments() {
      this.isBusy = true;

      const { IsSuccess, Data } = await Req({
        url: PlanningApi.InitialProvisioningDocuments,
        method: 'GET',
      });

      if (IsSuccess && Data?.initialProvisioningDocumentsList) {
        const data = Data.initialProvisioningDocumentsList as InitialProvisioningDocument[];
        const sortDocumentsByModifiedDate = data.sort((a, b) => {
          return new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime();
        });
        this.documents = sortDocumentsByModifiedDate;
      }

      this.isBusy = false;
      return { IsSuccess };
    },
    async downloadDocuments(codes: string[]) {
      await DownloadFile({
        url: PlanningApi.InitialProvisioningDocumentDownload,
        data: {
          codes,
        },
        method: 'POST',
      });
    },
    getCategoryOptions(): IVSelect[] {
      if (this.documents.length > 0) {
        const categoryCodes = this.documents.map(({ category }) => category.code);
        const uniqueCategories = [...new Set(categoryCodes)];

        const categoryOptions = uniqueCategories.map((category) => ({
          label: category,
          value: category,
        }));

        return [defaultSelectCategory, ...categoryOptions];
      }

      return [];
    },
    setSearchTerm(searchTerm: string) {
      this.searchTerm = searchTerm;
    },
    setSelectedCategory(category: IVSelect) {
      this.selectedCategory = category;
    },
    clearFilters() {
      this.searchTerm = '';
      this.selectedCategory = defaultSelectCategory;
    },
  },
});

<template>
  <div class="toolbar-button" :class="{ 'toolbar-button--cta': variant === uiVariant.Primary }">
    <Component :is="component" :disabled="!isMouseInput || disableTooltip">
      <button
        :disabled="disabled"
        class="toolbar-button__button"
        v-ui-test="uiTestNameButton"
        :class="{ 'toolbar-button__button--multiline-search': multilineSearch }"
        @click="$emit('click', $event)"
        v-on="onClick ? { click: ($event) => onClick($event) } : {}"
      >
        <DotLoader
          class="toolbar-button-loader"
          :class="{ 'toolbar-button-loader--visible': loading }"
          :loading="loading"
        ></DotLoader>
        <span class="toolbar-button__icon" :class="{ 'toolbar-button__icon--hidden': loading }">
          <svgicon :name="icon"></svgicon>
        </span>
        <span>
          <slot name="label" />
        </span>
      </button>
      <template #tooltip-content>
        <slot />
      </template>
    </Component>
    <slot name="append" />
  </div>
</template>

<style lang="scss" src="./toolbar-button.scss" scoped></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { WhatInputBus } from '@/src/core/utils/what-input-event-bus';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';
import { computed, onUnmounted, ref } from 'vue';

interface Props {
  icon: string;
  variant?: UiVariant;
  onClick?: ($event?: Event) => void;
  hasTooltip?: boolean;
  disabled?: boolean;
  loading?: boolean;
  disableTooltip?: boolean;
  multilineSearch?: boolean;
  uiTestNameButton?: string;
}

const props = withDefaults(defineProps<Props>(), {
  variant: UiVariant.Default,
  onClick: () => {},
  hasTooltip: true,
  disabled: false,
  loading: false,
  disableTooltip: false,
  multilineSearch: false,
  uiTestNameButton: 'toolbar-button',
});

const uiVariant = UiVariant;
const isMouseInput = ref(false);

const component = computed(() => {
  return props.hasTooltip ? Tooltip : 'div';
});

const inputTypeChanged = (type: string) => {
  isMouseInput.value = type === 'mouse';
};

isMouseInput.value = WhatInputHandler.hasMouse();
WhatInputBus.$on('inputTypeChanged', inputTypeChanged);

onUnmounted(() => {
  WhatInputBus.$off('inputTypeChanged', inputTypeChanged);
});
</script>

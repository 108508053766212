import { CartEntry, CartEntryPricing, ProductAvailable } from '@/src/core/types/api';
import { ISellerGroup } from '@/src/core/types/interfaces';
import { DateFormat } from '@/src/core/utils/dates';
import { formatProductPrice } from '@/src/market/services/airbus-parts';
import debounce from 'lodash/debounce';

export const sellerHasNoPriceAvailable = (seller: ISellerGroup) => {
  return seller.Entries.some((entry) => !entry.PriceAvailable);
};

export const checkCartPriority = debounce(async function (this: {
  shippingPriority: string;
  setRegularShippingPriority: () => void;
}) {
  if (this.shippingPriority && this.shippingPriority === 'AOG') {
    this.setRegularShippingPriority();
  }
}, 5000);

export const mapPricingEntryToCartEntry = (
  pricingEntry?: CartEntryPricing | CartEntryPricing[],
  entry?: CartEntry,
): CartEntry => {
  if (entry?.HasMultipleWarehouse) {
    const pricingEntries = pricingEntry as CartEntryPricing[];

    const AvailabilityList = pricingEntries.map((entry) => {
      return availabilityListFromCartEntryPricing(entry);
    });

    return {
      ...entry,
      AvailabilityIndicator: getAvailabilityIndicator(AvailabilityList.flat()),
      AvailabilityList: AvailabilityList.flat(),
      BasePrice: formatProductPrice(pricingEntries[0]?.basePrice),
      PriceAvailable: pricingEntries[0]?.priceAvailable,
      SalesUnit: pricingEntries[0]?.salesUnit?.sapCode || pricingEntries[0]?.salesUnit?.code || '-',
      TotalPrice: formatProductPrice(pricingEntries[0]?.totalPrice),
      Quantity: pricingEntries[0]?.quantity || 0,
    } as CartEntry;
  } else {
    const pricing = pricingEntry as CartEntryPricing;
    const AvailabilityList = availabilityListFromCartEntryPricing(pricing) ?? [];

    return {
      ...entry,
      AvailabilityIndicator: getAvailabilityIndicator(AvailabilityList),
      AvailabilityList,
      BasePrice: formatProductPrice(pricing?.basePrice),
      PriceAvailable: pricing?.priceAvailable,
      SalesUnit: pricing?.salesUnit?.sapCode || pricing?.salesUnit?.code || '-',
      TotalPrice: formatProductPrice(pricing?.totalPrice),
      Quantity: pricing?.quantity || 0,
    } as CartEntry;
  }
};

export const getAvailabilityIndicator = (availabilityList?: ProductAvailable[]) => {
  const firstAvailable = availabilityList?.find((item) => item.StockCount > 0)?.AvailableDate;

  if (firstAvailable) {
    const formattedDate = DateFormat(firstAvailable);
    return availabilityList.length > 1 ? `From ${formattedDate}` : formattedDate;
  } else {
    return 'N/A';
  }
};

export const availabilityListFromCartEntryPricing = (entry?: CartEntryPricing) => {
  if (!entry?.availabilityInfos) {
    return [];
  }

  if (entry.availabilityInfos.length === 1 && entry.availabilityInfos[0].quantity === 0) {
    return [];
  }

  return entry.availabilityInfos
    .filter(({ quantity }) => quantity > 0)
    .map(
      ({ availabilityDate, warehouse, quantity }) =>
        ({
          Warehouse: { Name: warehouse?.name ?? '', Code: warehouse?.code ?? '' },
          AvailableDate: DateFormat(availabilityDate),
          InStock: false,
          IsAsmHidden: false,
          StockCount: quantity,
        }) as ProductAvailable,
    );
};

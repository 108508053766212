<template>
  <div class="content-modal">
    <StandardDialogModal
      :locked-modal="true"
      :size="uiSize.Large"
      :use-padding="false"
      :has-close-button="false"
    >
      <template #header>
        <h3>
          {{
            params.isChange
              ? textK('UI_TERMS_TOU_HEADLINE_CHANGES')
              : textK('UI_TERMS_TOU_HEADLINE')
          }}
        </h3>
      </template>
      <template #body>
        <ModalColumnContainer>
          <ModalColumn>
            <div class="content-modal-wrapper">
              <ContentBlock :modal="true" link-key="TermsOfUse_ModalUrl"></ContentBlock>
            </div>
          </ModalColumn>
        </ModalColumnContainer>
      </template>
      <template #actions>
        <Checkbox v-model="terms" :id="'terms'" v-ui-test="'terms-of-use-modal-accept'">{{
          textK('UI_TERMS_TOU_ACCEPT')
        }}</Checkbox>
        <VButton
          input-type="button"
          :v-ui-test="'terms-of-use-modal-continue'"
          :disabled="!terms"
          @click="submit"
        >
          Continue</VButton
        >
      </template>
    </StandardDialogModal>
  </div>
</template>
<style lang="scss" src="./modal-accept-terms-of-use.scss" scoped></style>

<script lang="ts" setup>
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalLoadContent } from '@/src/core/types/interfaces';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, ref } from 'vue';

const textK = useText();
const modalStore = useModalStore();
const userStore = useUserStore();

const uiSize = UiSize;
const terms = ref(false);

const params = computed(() => modalStore.params as IModalLoadContent);

const submit = () => {
  if (terms.value) {
    userStore.acceptTOU().then((response) => {
      if (response.IsSuccess) {
        modalStore.closeModal();
      }
    });
  }
};
</script>

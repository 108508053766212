<template>
  <div class="adobe-target-wrapper" ref="wrapper" :class="classes">
    <template v-if="hasOffer && checkedForOffer && offerComponent">
      <TransitionExpand v-if="!showPlaceholder">
        <Component
          :is="offerComponent"
          :offer="offer"
          @loading="loading = $event"
          :target-id="id"
        ></Component>
      </TransitionExpand>
      <template v-else>
        <Component
          :is="offerComponent"
          :offer="offer"
          @loading="loading = $event"
          :target-id="id"
        ></Component>
      </template>
    </template>
    <template v-else-if="!hasOffer && checkedForOffer">
      <slot></slot>
    </template>
    <div
      class="adobe-target-placeholder"
      v-if="showPlaceholder && !(checkedForOffer && !loading)"
    ></div>
  </div>
</template>

<style src="./adobe-target-wrapper.scss" lang="scss" scoped></style>

<script lang="ts">
import { Vue, Component, Prop, Watch, toNative } from 'vue-facing-decorator';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import { ApplyOffer } from '@/src/core/services/adobe-target';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { IAdobeTargetOffer } from '@/src/core/types/adobe';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { useUserStore } from '@/src/profile/stores/user';

@Component({
  components: {
    ContentBlock,
    TransitionExpand,
  },
})
export class AdobeTargetWrapper extends Vue {
  @Prop() public id: string;
  @Prop({ default: false }) public showPlaceholder: boolean;

  public authenticationStore = useAuthenticationStore();
  public hasOffer: boolean = true;
  public checkedForOffer: boolean = false;
  public offer: IAdobeTargetOffer | null = null;
  public loading: boolean = true;
  public classes: string[] = [];
  public component: string | null = null;
  public userStore = useUserStore();

  public $refs: {
    wrapper: HTMLElement;
  };

  private get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }

  private get accountId() {
    return this.userStore.accountId;
  }

  private async getOffers() {
    this.component = null;
    const { IsSuccess, Offers } = await ApplyOffer(this.id);
    const offer = Offers && Offers.length > 0 && Offers[0];

    if (IsSuccess && offer) {
      this.offer = offer;
      this.hasOffer = true;
      this.checkedForOffer = true;
    } else {
      this.hasOffer = false;
      this.checkedForOffer = true;
      this.loading = false;
    }
    ResizeEventBus.$emit('resize');
  }

  public offerComponent() {
    return this.offer?.content[0]?.component;
  }

  public mounted() {
    this.getOffers();

    /* Remove SSR hide class for adobetarget enabled modules - and add child classes to root (to insure correct design/overlay) */
    this.$refs.wrapper.removeAttribute('style');

    this.$nextTick(() => {
      // @ts-ignore
      this.classes = this.$refs.wrapper.childNodes[0].className;
    });
  }

  public destroyed() {
    this.hasOffer = false;
  }

  @Watch('isAuthenticated') private onAuthChange(oldValue: boolean, newValue: boolean) {
    if (oldValue !== newValue) {
      this.getOffers();
    }
  }

  @Watch('accountId') private accountChange(oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      this.getOffers();
    }
  }
}

export default toNative(AdobeTargetWrapper);
</script>

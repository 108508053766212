import { getEnv } from '@/src/core/services/environment';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { srUnescape } from '@/src/core/utils/unescape';
import { defineStore } from 'pinia';

interface State {
  umbracoContent: string;
  url: string;
  isBusy: boolean;
}

export const useContentStore = defineStore('contentStore', {
  state: (): State => ({
    umbracoContent: '',
    url: '',
    isBusy: false,
  }),
  actions: {
    isBusyUpdated(payload: { isBusy: boolean }) {
      this.isBusy = payload.isBusy;
    },
    contentUpdated(payload: { umbracoContent: string; url: string }) {
      this.umbracoContent = payload.umbracoContent;
      this.url = payload.url;
    },
    reset() {
      this.$reset();
    },
    async fetchUmbracoContent(payload: { contentURL: string; force?: boolean }) {
      if (!payload.force && payload.contentURL === this.url) {
        return;
      }

      this.isBusyUpdated({ isBusy: true });

      const { IsSuccess, Data } = await Req(
        {
          url: `${getEnv('VUE_APP_HOST_UMBRACO') ?? ''}${payload.contentURL}`,
        },
        new ReqQueue(ReqQueueTypes.Default, 'content'),
      );

      if (IsSuccess) {
        this.contentUpdated({ umbracoContent: Data || '<span></span>', url: payload.contentURL });
      }

      this.isBusyUpdated({ isBusy: false });
    },
    async setUmbracoContent(payload: { umbracoContent: string; contentURL: string }) {
      this.contentUpdated({
        umbracoContent: srUnescape(payload.umbracoContent),
        url: payload.contentURL,
      });
    },
  },
});

<template>
  <div class="modal-request-quote typeset" v-ui-test="'airbus-repair-request-quote-modal'">
    <StandardDialogContainer class="list-modal" :size="uiSize.Large" :has-close-button="true">
      <div
        class="modal-request-quote__inner modal-request-quote__inner__spacing"
        ref="scrollcontainer"
      >
        <ModalAirbusRepairRequestQuoteHeader :id="params.productId" />
        <div class="advanced-modal__body">
          <div class="modal-request-quote__body-wrapper">
            <div class="modal-request-quote__body__description pt-lg">
              <p>{{ textK('UI_REPAIR_QUOTATION_DESCRIPTION') }}:</p>
            </div>
            <form>
              <section class="pt-sm">
                <div class="flex gap-lg" v-if="params.serialNumberRequired">
                  <VTextField
                    class="flex-grow"
                    input-type="text"
                    :type="UiVariant.Secondary"
                    label="Serial number"
                    :required="params.serialNumberRequired"
                    v-model="formDetails.serialNumber"
                  />
                  <VTextField
                    class="flex-grow"
                    input-type="text"
                    :type="UiVariant.Secondary"
                    label="Flight hours/cycles"
                    :required="!formDetails.flightHoursUnkown"
                    v-model="formDetails.flightHours"
                  />
                </div>
                <div class="flex pt-lg gap-lg" v-if="params.serialNumberRequired">
                  <span class="flex-grow"></span>
                  <Checkbox v-model="formDetails.flightHoursUnkown" class="flex-grow">
                    Flying hours/cycles unknown
                  </Checkbox>
                </div>
                <div class="flex pt-lg gap-lg">
                  <DatePickerWrapper
                    class="flex-grow"
                    label="Removal date"
                    datepicker-type="text"
                    v-ui-test="'removal-date'"
                    @apply="applyRemovalDate"
                    @closed="togglePicker"
                    :value="formDetails.removalDate"
                    :minDate="dayjs().format(DisplayDateFormat)"
                    week-start="1"
                    :type="uiVariant.Secondary"
                  >
                    <template #label>
                      <VTextField
                        @click="togglePicker"
                        :required="true"
                        class="flex-grow removal-date"
                        :type="UiVariant.Secondary"
                        :label="'Removal date'"
                        :model-value="formDetails.removalDate"
                        :readonly="true"
                      >
                      </VTextField>
                    </template>
                  </DatePickerWrapper>
                  <VTextField
                    class="flex-grow"
                    input-type="text"
                    :type="UiVariant.Secondary"
                    label="Removal Reason"
                    :required="true"
                    v-model="formDetails.removalReason"
                  />
                </div>
                <div class="flex pt-lg gap-lg">
                  <VTextField
                    class="flex-grow"
                    input-type="text"
                    :type="UiVariant.Secondary"
                    label="AC Reg. or MSN"
                    :required="true"
                    v-model="formDetails.acReg"
                    :error-message="errors?.acReg && errors.acReg[0]"
                  />
                  <div class="flex-grow">
                    <VSelect
                      class="dropdown"
                      :required="true"
                      :options-skeleton="certifcateTypeOptions.length === 0"
                      label="Certificate type"
                      :model-value="formDetails.certificateRequiredAfterRepair"
                      @update:modelValue="handleCertificateTypeSelect"
                      :is-input-field="false"
                      :options="certifcateTypeOptions"
                      :type="UiVariant.Secondary"
                      ui-test-name-dropdown="request-repair-quote-certificate-types"
                    />
                    <Tooltip class="flex-grow pt-lg">
                      <span class="flex gap-sm align-items-center pt-sm">
                        {{ textK('UI_REPAIR_QUOTATION_CERTIFICATE_TYPE_EXPLAINER') }}
                        <svgicon #icon name="ui-info" />
                      </span>
                      <template #tooltip-content>
                        {{ textK('UI_REPAIR_QUOTATION_CERTIFICATE_TYPE_EXPLAINER_TEXT') }}
                      </template>
                    </Tooltip>
                  </div>
                </div>
                <div class="pt-lg">
                  <VTextField
                    class="flex-grow"
                    input-type="text"
                    :type="UiVariant.Secondary"
                    label="Reference"
                    :required="true"
                    v-model="formDetails.reference"
                    :vmax-length="11"
                    regex="^[A-Za-z0-9\s\-]+$"
                  />
                </div>
                <div class="pt-lg">
                  <VTextarea
                    v-ui-test="'request-repair-message'"
                    :type="UiVariant.Secondary"
                    label="Message"
                    :vmax-length="1000"
                    v-model="formDetails.message"
                    name="message"
                  />
                </div>
              </section>
            </form>
          </div>
          <div class="modal-request-quote__actions-wrapper">
            <VButton
              class="modal-request-quote__submit"
              :disabled="!isValid"
              @click.prevent="onSubmit"
              :context="uiContext.ShopSite"
              :loading="isBusy"
              >{{ textK('UI_REPAIR_QUOTATION_REQUEST_CTA') }}</VButton
            >
          </div>
        </div>
      </div>
    </StandardDialogContainer>
  </div>
</template>

<style lang="scss" src="../modal-request-quote/modal-request-quote.scss" scoped></style>

<script setup lang="ts">
import StandardDialogContainer from '@/src/core/components/modals/ui/standard-dialog-layout/standard-dialog-container.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiContext, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import {
  ModalRepairRequestQuoteParams,
  RepairRequestQuoteForm,
  RepairRequestQuoteFormRequest,
} from '@/src/core/types/interfaces';
import { IVSelect } from '@/src/core/types/ui';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { getSelectedValue, mapToSelectOption } from '@/src/core/utils/select';
import ModalAirbusRepairRequestQuoteHeader from '@/src/market/components/modals/modal-airbus-repair-request-quote/modal-airbus-repair-request-quote-header/modal-airbus-repair-request-quote-header.vue';
import { useRequestRepairQuoteStore } from '@/src/market/stores/request-repair-quotes';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { custom, flatten, maxLength, minLength, object, parse, string } from 'valibot';
import { Ref, computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';

const repairQuoteStore = useRequestRepairQuoteStore();
const modalStore = useModalStore();
const userStore = useUserStore();

const params = computed(() => modalStore.params as ModalRepairRequestQuoteParams);
const productId = (modalStore.params as ModalRepairRequestQuoteParams).productId;
const textK = useText();

const uiSize = UiSize;
const uiContext = UiContext;
const uiVariant = UiVariant;

const initialFormState = {
  serialNumber: '',
  flightHours: '',
  flightHoursUnkown: false,
  acReg: '',
  removalDate: '',
  removalReason: '',
  certificateRequiredAfterRepair: null,
  reference: '',
  message: '',
};
const lastSavedSession = repairQuoteStore.getFieldsByProductId(params.value.productId);
const formDetails = reactive<RepairRequestQuoteForm>(
  lastSavedSession ? { ...lastSavedSession } : { ...initialFormState },
);
const isDatePickerOpen = ref(false);
const certifcateTypeOptions: Ref<IVSelect[]> = ref([]);
const isValid = ref(false);
const errors = ref<Partial<Record<any, [string, ...string[]]>> | null>(null);
const isBusy = computed(() => repairQuoteStore.isBusy);
const isSubmitSuccess = ref(false);

const repairQuoteSchema = object({
  serialNumber: string([
    maxLength(255),
    custom((input: string) => {
      if (input.length === 0 && !params.value.serialNumberRequired) {
        return true;
      } else if (input.length > 0 && params.value.serialNumberRequired) {
        return true;
      } else {
        return false;
      }
    }),
  ]),
  flightHours: string([
    maxLength(255),
    custom((input: string) => {
      if (formDetails.flightHoursUnkown) {
        return true;
      } else if (!params.value.serialNumberRequired) {
        return true;
      } else if (input.length > 0) {
        return true;
      }
      return false;
    }),
  ]),
  acReg: string([minLength(2, 'AC Reg or MSN must have at least 2 characters'), maxLength(255)]),
  removalDate: string([minLength(1)]),
  removalReason: string([minLength(1), maxLength(255)]),
  certificateRequiredAfterRepair: string([minLength(1)]),
  reference: string([minLength(1), maxLength(11)]),
  message: string([maxLength(1000)]),
});

const togglePicker = () => {
  isDatePickerOpen.value = !isDatePickerOpen.value;
};

const applyRemovalDate = (datepickerValue: string) => {
  if (datepickerValue) {
    formDetails.removalDate = dayjs(datepickerValue).format(DisplayDateFormat);
  }
};

const formRequestObj = computed((): RepairRequestQuoteFormRequest => {
  const { certificateRequiredAfterRepair, ...rest } = formDetails;

  return {
    ...rest,
    certificateRequiredAfterRepair: getSelectedValue(
      certificateRequiredAfterRepair,
      certifcateTypeOptions.value,
    ),
  };
});

const updateFormFields = (newValue: RepairRequestQuoteFormRequest) => {
  try {
    parse(repairQuoteSchema, newValue);
    isValid.value = true;
    errors.value = null;
  } catch (error) {
    isValid.value = false;
    errors.value = flatten<typeof repairQuoteSchema>(error).nested;
  }
};

watch(() => formRequestObj.value, updateFormFields, { immediate: true });

const handleCertificateTypeSelect = (value: number | null) => {
  formDetails.certificateRequiredAfterRepair = value;
};

const showBlockSuccessContent = () => {
  const modalKey = 'AirbusRFQConfirmationPartRepair';
  modalStore.showModal({
    modalComponent: 'ModalLoadContent',
    params: { key: `${modalKey}_ModalUrl`, size: uiSize.Medium, title: `${modalKey}_Name` },
  });
};

const closeModal = () => {
  modalStore.closeModal();
};

const onSubmit = async () => {
  const { GlobalId } = userStore.currentUser;

  const IsSuccess = await repairQuoteStore.onSubmit(
    {
      productId: params.value.productId,
      serialNumberRequired: params.value.serialNumberRequired,
      procurementType: params.value.procurementType,
      globalId: GlobalId,
    },
    formRequestObj.value,
  );

  if (IsSuccess) {
    repairQuoteStore.clearForm(productId);
    isSubmitSuccess.value = true;
    closeModal();
    showBlockSuccessContent();
  }
};

onMounted(async () => {
  await repairQuoteStore.fetchCertificates();
  certifcateTypeOptions.value = repairQuoteStore.certificateTypes?.map(mapToSelectOption) ?? [];
});

onUnmounted(() => {
  if (!isSubmitSuccess.value) {
    repairQuoteStore.saveForm(productId, formDetails);
  }
});
</script>

import accountRoutes from '@/src/account/router';
import contentRoutes from '@/src/content/router';
import AuthOrLogin from '@/src/core/router/auth-or-login/auth-or-login';
import DetectSiteChange from '@/src/core/router/detectSiteChange';
import RouteAuthenticationGate from '@/src/core/router/route-authentication-gate/route-authentication-gate';
import UiAnimationGate from '@/src/core/router/ui-animation/ui-animation';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { RouteConfigMeta } from '@/src/core/types/interfaces';
import { adobeTargetTriggerView } from '@/src/core/utils/adobe-target';
import financeRoutes from '@/src/finance/router';
import marketRoutes from '@/src/market/router';
import oidcRoutes from '@/src/oidc/router';
import planningRoutes from '@/src/planning/router';
import profileRoute from '@/src/profile/router';
import debounce from 'lodash/debounce';
import { nextTick } from 'vue';
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouteMeta,
  START_LOCATION,
} from 'vue-router';
import { useMaintenanceStore } from '../stores/maintenance';

const routes: RouteRecordRaw[] = [
  ...marketRoutes,
  ...financeRoutes,
  ...profileRoute,
  ...accountRoutes,
  ...planningRoutes,
  ...oidcRoutes,
  // contentRoutes need to be last, because of the catch-all route
  ...contentRoutes,
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior: (to, from) => {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' };
    }

    // Don't scroll to top on the brand site
    if (to.name === 'mainUmbracoContent') {
      return;
    }

    // Don't scroll to top when route only change queries
    if (from.path === to.path) {
      return;
    }

    if (
      (from.name === 'orders' && to.name === 'ordersSale') ||
      (from.name === 'ordersLease' && to.name === 'leaseOrder') ||
      (from.name === 'ordersRepairExchange' && to.name === 'repairExchangeOrder') ||
      (from.name === 'search' && to.name === 'offer') ||
      (from.name === 'checkout' && to.name === 'checkout-seller')
    ) {
      return;
    }

    // Dpn't scroll to top when the last route is on detail page
    if (!from.meta?.isDetailPage) {
      return { left: 0, top: 0, behavior: 'smooth' };
    }

    return;
  },
});

router.beforeEach(async (to, from, next) => {
  const routeConfigStore = useRouteConfigStore();
  const maintenanceStore = useMaintenanceStore();
  maintenanceStore.fetchMaintenanceModeStatus();

  if (!routeConfigStore.isFetched) {
    await routeConfigStore.fetchRouteConfig();
  }

  let meta: RouteConfigMeta | RouteMeta | null = null;
  const metaFromRouteConfig = routeConfigStore.getMenuConfig((to?.name as string) || '', 'meta');

  if (to.name && metaFromRouteConfig) {
    // Umbraco config
    meta = metaFromRouteConfig;
  } else if (to.name && to.meta) {
    // router meta (eg, from 'market' router.ts )
    meta = to.meta;

    if (to.meta.title) {
      document.title = to.meta.title as string;
    }
  }

  if (meta && meta.title) {
    document.title = meta.title as string;
  }

  // NOTES:
  // If not loginRestricted, then allow all
  // Else check Auth, beta restriction or access restriction
  if (!to.meta?.loginRestricted) {
    DetectSiteChange(to, from, next);
  } else {
    AuthOrLogin({
      cb: () => DetectSiteChange(to, from, next),
      betaRestricted: to.meta.betaRestricted as string,
      allowAccess: Object.prototype.hasOwnProperty.call(to.meta, 'accessRestricted')
        ? to.meta.accessRestricted
        : false,
    });

    next();
  }

  nextTick(() => {
    RouteAuthenticationGate();
    UiAnimationGate();
  });
});

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const maintenanceStore = useMaintenanceStore();

  if (
    maintenanceStore.maintenanceMode &&
    to.matched[0].name === 'market' &&
    to.name !== 'serviceunavailable'
  ) {
    router.push({ name: 'serviceunavailable' });
  }
  const path: string = to.fullPath;
  adobeTargetTriggerView(path.length > 0 ? path : 'frontpage', { page: true });
  const debounceFunction = debounce(
    (reload = false) => AdobeLaunchTracking.pageView(to, from, to.meta!, reload),
    1000,
  );

  if (from === START_LOCATION) {
    debounceFunction(true);
  } else {
    if (to.name !== from.name) {
      AdobeLaunchTracking.pageView(to, from, to.meta!);
    }
  }
});

export default router;

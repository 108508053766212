import NotFoundLayout from '@/src/core/layouts/error-pages/not-found/not-found.vue';
import RestrictedLayout from '@/src/core/layouts/error-pages/restricted/restricted.vue';
import ServiceUnavailableLayout from '@/src/core/layouts/error-pages/service-unavailable/service-unavailable.vue';
import DetectSiteChange from '@/src/core/router/detectSiteChange';
import ValidateToken from '@/src/core/router/validate-token/validate-token';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { Site, useUIStore } from '@/src/core/stores/ui';
import ProductDetailsLayout from '@/src/market/layouts/product-details/product-details-layout.vue';
import SellerDetailsLayout from '@/src/market/layouts/seller/seller-details-layout.vue';
import {
  leaseOrderBeforeEnterFeatureCheck,
  quotesBeforeEnterFeatureCheck,
  repairExchangeBeforeEnterFeatureCheck,
} from '@/src/market/services/router-service';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const MarketLayout = () => import('@/src/market/layouts/market-layout.vue');
const MarketPortalLayout = () => import('@/src/market/layouts/portal/market-portal-layout.vue');
const SearchLayout = () => import('@/src/market/layouts/search/search-layout.vue');
const ListsLayout = () => import('@/src/market/layouts/lists/lists-layout.vue');
const ListLayout = () => import('@/src/market/layouts/lists/list-details/list-details-layout.vue');
const OrdersLayout = () => import('@/src/market/layouts/orders/orders-layout.vue');
const OrdersDetailsLayout = () =>
  import('@/src/market/layouts/orders/order-details/order-details-layout.vue');
const OrderDetailsProductLines = () =>
  import(
    '@/src/market/components/orders/order-details/order-details-product-lines/order-details-product-lines.vue'
  );
const ServiceOrderProductLines = () =>
  import(
    '@/src/market/components/orders/order-details/service-order-product-lines/service-order-product-lines.vue'
  );
const OrderDetailsUpdates = () =>
  import(
    '@/src/market/components/orders/order-details/order-details-updates/order-details-updates.vue'
  );
const CheckoutLayout = () => import('@/src/market/layouts/cart/checkout/checkout-layout.vue');
const CartLayout = () => import('@/src/market/layouts/cart/cart-layout.vue');
const ReceiptLayout = () => import('@/src/market/layouts/cart/receipt/receipt-layout.vue');
const CartList = () => import('@/src/market/components/cart/cart.vue');
const StyleguideLayout = () => import('@/src/core/layouts/styleguide/styleguide.vue');
const QuotesLayout = () => import('@/src/market/layouts/quotes/quotes-layout.vue');
const QuoteDetailsLayout = () =>
  import('@/src/market/layouts/quotes/quote-details/quote-details-layout.vue');
const QuoteDrafts = () => import('@/src/market/components/quotes/quotes/drafts/drafts.vue');
const QuoteSubmittedQuotes = () =>
  import('@/src/market/components/quotes/quotes/submitted-quotes/submitted-quotes.vue');

const marketRoutes: RouteRecordRaw[] = [
  {
    path: '/market',
    name: 'market',
    redirect: { name: 'search' },
    component: MarketLayout,
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) {
      const uiStore = useUIStore();
      uiStore.setSplashScreen({ splashScreen: true });
      uiStore.setCurrentSite({ site: Site.Market });
      next();
    },
    children: [
      {
        path: '/market/product',
        redirect: { name: 'search' },
        name: 'product-search',
        meta: {
          title: 'Search',
          type: 'Search page',
        },
      },
      {
        path: '/market/product/:manufacturer/:manufacturerAid',
        name: 'product',
        components: {
          marketRoute: SearchLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          title: 'Search',
          type: 'Product Detail Page',
          isDetailPage: true,
        },
      },
      {
        path: '/market/product/:manufacturer/:manufacturerAid/:offerId',
        name: 'offer',
        components: {
          marketRoute: SearchLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          title: 'Search',
          type: 'Product Detail Page',
          isDetailPage: true,
        },
      },
      {
        path: '/market/product/:manufacturer/:manufacturerAid/:offerId/:procurementType',
        name: 'procurementType',
        meta: {
          title: 'Search',
          type: 'Product Detail page',
          isDetailPage: true,
        },
        components: {
          marketRoute: SearchLayout,
          subContentRoute: ProductDetailsLayout,
        },
      },
      {
        path: '/market/product/:manufacturer/:manufacturerAid/:offerId/:procurementType/:serialNr',
        name: 'serialNr',
        components: {
          marketRoute: SearchLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          title: 'Search',
          type: 'Product Detail page',
          isDetailPage: true,
        },
      },
      {
        path: '/market/seller/:sellerId',
        name: 'seller',
        components: {
          marketRoute: SearchLayout,
          subContentRoute: SellerDetailsLayout,
        },
        meta: {
          isDetailPage: true,
        },
      },
      {
        path: '/market/orders/seller/:sellerId',
        name: 'orders-seller',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: SellerDetailsLayout,
        },
        meta: {
          isDetailPage: true,
        },
      },
      {
        path: '/market/quotes/seller/:sellerId',
        name: 'quotes-seller',
        components: {
          marketRoute: QuotesLayout,
          subContentRoute: SellerDetailsLayout,
        },
        meta: {
          isDetailPage: true,
        },
        beforeEnter: quotesBeforeEnterFeatureCheck,
      },
      {
        path: '/market/lists/seller/:sellerId',
        name: 'lists-seller',
        components: {
          marketRoute: ListsLayout,
          subContentRoute: SellerDetailsLayout,
        },
      },
      {
        path: '/market/cart/seller/:sellerId',
        components: {
          marketRoute: CartLayout,
          subContentRoute: SellerDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        children: [
          {
            name: 'cart-seller',
            path: '',
            component: CartList,
          },
        ],
      },
      {
        path: '/market/reset-password',
        name: 'resetpassword',
        components: {
          marketRoute: SearchLayout,
        },
        meta: {
          title: 'Reset Password',
          type: 'Search page',
        },
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
          DetectSiteChange(to, from, () => {
            const authenticationStore = useAuthenticationStore();
            next();
            // FORCE logout when resetting password
            authenticationStore.doLogout(true).then(() => {
              // GET AND VALIDATE TOKEN
              const tokenQuery = to.query.token
                ? (to.query.token as string).replace(/ /g, '+')
                : undefined;

              // If undefined do nothing (will keep the user on the market frontpage)
              if (!tokenQuery) {
                return;
              }
              ValidateToken(tokenQuery).then(({ IsSuccess }) => {
                const modalStore = useModalStore();
                if (IsSuccess) {
                  modalStore.showModal({
                    modalComponent: 'ModalResetPassword',
                    first: true,
                    single: true,
                    params: {
                      email: to.query.email,
                      token: tokenQuery,
                    },
                  });
                } else {
                  modalStore.showModal({
                    modalComponent: 'ModalInvalidToken',
                    first: true,
                    single: true,
                  });
                }
              });
            });
          });
        },
      },
      {
        path: '/market/welcome',
        name: 'welcome',
        components: {
          marketRoute: SearchLayout,
        },
        meta: {
          title: "Welcome and let's get started",
          type: 'Search page',
        },
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
          DetectSiteChange(to, from, () => {
            const authenticationStore = useAuthenticationStore();
            next();
            // FORCE logout when resetting password
            authenticationStore.doLogout(true).then(() => {
              // GET AND VALIDATE TOKEN
              const tokenQuery = to.query.token
                ? (to.query.token as string).replace(/ /g, '+')
                : undefined;

              // If undefined do nothing (will keep the user on the market frontpage)
              if (!tokenQuery) {
                return;
              }
              ValidateToken(tokenQuery).then(({ IsSuccess }) => {
                const modalStore = useModalStore();

                if (IsSuccess) {
                  modalStore.showModal({
                    modalComponent: 'ModalResetPassword',
                    first: true,
                    single: true,
                    params: {
                      email: to.query.email,
                      token: tokenQuery,
                    },
                  });
                } else {
                  modalStore.showModal({
                    modalComponent: 'ModalInvalidToken',
                    first: true,
                    single: true,
                  });
                }
              });
            });
          });
        },
      },
      {
        path: '/market/search',
        name: 'search',
        components: {
          marketRoute: SearchLayout,
        },
        meta: {
          title: 'Search',
          type: 'Search page',
        },
      },
      {
        path: '/market/lists',
        name: 'lists',
        components: {
          marketRoute: ListsLayout,
        },
        meta: {
          title: 'Lists',
          type: 'List page',
          loginRestricted: true,
        },
      },
      {
        path: '/market/lists/:listid',
        name: 'list',
        components: {
          marketRoute: ListsLayout,
          subContentRoute: ListLayout,
        },
        props: {
          market: true,
        },
        meta: {
          title: 'List',
          type: 'List page',
          loginRestricted: true,
          isDetailPage: true,
        },
      },
      {
        path: '/market/search/multiline-search',
        name: 'multiline',
        components: {
          marketRoute: SearchLayout,
          subContentRoute: ListLayout,
        },
        props: {
          market: true,
        },
        meta: {
          title: 'Multiline Search',
          type: 'Multiline page',
          loginRestricted: true,
        },
      },
      {
        path: '/market/quotes/multiline-quote',
        name: 'multilinequote',
        components: {
          marketRoute: QuotesLayout,
          subContentRoute: ListLayout,
        },
        props: {
          market: true,
        },
        meta: {
          title: 'Create Quote',
          type: 'Multiline page',
          loginRestricted: true,
        },
      },
      {
        name: 'listproduct',

        path: '/market/lists/:listid/:manufacturer/:manufacturerAid/',
        components: {
          marketRoute: ListsLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
      },
      {
        name: 'listoffer',

        path: '/market/lists/:listid/:manufacturer/:manufacturerAid/:offerId',
        components: {
          marketRoute: ListsLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          title: 'List',
          type: 'List page',
          isDetailPage: true,
        },
      },
      {
        path: '/market/orders',
        name: 'orders',
        components: {
          marketRoute: OrdersLayout,
        },
        meta: {
          title: 'Orders',
          type: 'Orders page',
          loginRestricted: true,
        },
        children: [
          {
            path: '/market/orders',
            name: 'ordersSale',
            meta: {
              title: 'Sale orders',
              type: 'Orders page',
              loginRestricted: true,
            },
            components: {
              marketRoute: OrdersLayout,
            },
          },
          {
            path: '/market/orders/repair-exchange',
            name: 'ordersRepairExchange',
            meta: {
              title: 'Repair/Exchange orders',
              type: 'Orders page',
              loginRestricted: true,
            },
            components: {
              marketRoute: OrdersLayout,
            },
          },
          {
            path: '/market/orders/lease',
            name: 'ordersLease',
            meta: {
              title: 'Lease orders',
              type: 'Orders page',
              loginRestricted: true,
            },
            components: {
              marketRoute: OrdersLayout,
            },
          },
        ],
      },
      // REPAIR EXCHANGE ORDER DETAILS
      {
        path: '/market/orders/repair-exchange/:ordercode',
        meta: {
          title: 'Repair and Exchange order',
          type: 'Orders page',
          loginRestricted: true,
          isDetailPage: true,
        },
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: OrdersDetailsLayout,
        },
        children: [
          {
            path: '/market/orders/repair-exchange/:ordercode',
            name: 'repairExchangeOrder',
            meta: {
              title: 'Repair and Exchange order',
              type: 'Orders page',
              loginRestricted: true,
              isDetailPage: true,
            },
            component: ServiceOrderProductLines,
          },
        ],
        beforeEnter: repairExchangeBeforeEnterFeatureCheck,
      },
      {
        name: 'repairExchangeOrderProduct',
        path: '/market/orders/repair-exchange/:ordercode/:manufacturer/:manufacturerAid',

        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        beforeEnter: repairExchangeBeforeEnterFeatureCheck,
      },
      {
        name: 'repairExchangeOrderOffer',
        path: '/market/orders/repair-exchange/:ordercode/:manufacturer/:manufacturerAid/:offerId/:procurement',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        beforeEnter: repairExchangeBeforeEnterFeatureCheck,
      },
      // LEASE ORDER DETAILS
      {
        path: '/market/orders/lease/:ordercode',
        meta: {
          title: 'Lease order',
          type: 'Orders page',
          loginRestricted: true,
          isDetailPage: true,
        },
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: OrdersDetailsLayout,
        },
        children: [
          {
            path: '/market/orders/lease/:ordercode',
            name: 'leaseOrder',
            meta: {
              title: 'Lease order',
              type: 'Orders page',
              loginRestricted: true,
              isDetailPage: true,
            },
            component: ServiceOrderProductLines,
          },
        ],
        beforeEnter: leaseOrderBeforeEnterFeatureCheck,
      },
      {
        name: 'leaseOrderProduct',
        path: '/market/orders/lease/:ordercode/:manufacturer/:manufacturerAid',

        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        beforeEnter: leaseOrderBeforeEnterFeatureCheck,
      },
      {
        name: 'leaseOrderOffer',

        path: '/market/orders/lease/:ordercode/:manufacturer/:manufacturerAid/:offerId/:procurement',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        beforeEnter: leaseOrderBeforeEnterFeatureCheck,
      },
      {
        path: '/market/orders/lease/:ordercode',
        meta: {
          title: 'Lease order',
          type: 'Orders page',
          loginRestricted: true,
          isDetailPage: true,
        },
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: OrdersDetailsLayout,
        },
        children: [
          {
            path: '/market/orders/lease/:ordercode',
            name: 'leaseOrder',
            meta: {
              title: 'Lease order',
              type: 'Orders page',
              loginRestricted: true,
              isDetailPage: true,
            },
            component: ServiceOrderProductLines,
          },
        ],
        beforeEnter: leaseOrderBeforeEnterFeatureCheck,
      },
      {
        name: 'leaseOrderProduct',

        path: '/market/orders/lease/:ordercode/:manufacturer/:manufacturerAid',

        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        beforeEnter: leaseOrderBeforeEnterFeatureCheck,
      },
      {
        name: 'leaseOrderOffer',
        path: '/market/orders/lease/:ordercode/:manufacturer/:manufacturerAid/:offerId/:procurement',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
        beforeEnter: leaseOrderBeforeEnterFeatureCheck,
      },
      // SALE ORDER DETAILS
      {
        path: '/market/orders/:ordercode',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: OrdersDetailsLayout,
        },
        meta: {
          title: 'Order',
          type: 'Order Detail Page',
          loginRestricted: true,
          isDetailPage: true,
        },
        children: [
          {
            path: '/market/orders/:ordercode',
            name: 'order',
            meta: {
              title: 'Order',
              type: 'Order Detail Page',
              loginRestricted: true,
              isDetailPage: true,
            },
            component: OrderDetailsProductLines,
          },
          {
            path: '/market/orders/:ordercode/updates',
            name: 'updates',
            meta: {
              title: 'Order',
              type: 'Order page',
              loginRestricted: true,
              isDetailPage: true,
            },
            component: OrderDetailsUpdates,
          },
        ],
      },
      {
        name: 'orderproduct',
        path: '/market/orders/:ordercode/:manufacturer/:manufacturerAid',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
      },
      {
        name: 'orderoffer',
        path: '/market/orders/:ordercode/:manufacturer/:manufacturerAid/:offerId',
        components: {
          marketRoute: OrdersLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          isDetailPage: true,
        },
      },
      {
        path: '/market/quotes',
        name: 'quotes',
        components: {
          marketRoute: QuotesLayout,
        },
        redirect: { name: 'submittedQuotes' },
        meta: {
          title: 'Quotes',
          type: 'Quotes page',
          loginRestricted: true,
          betaRestricted: 'quotes',
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return !authenticationStore.readOnlyAccount;
          },
        },
        beforeEnter: quotesBeforeEnterFeatureCheck,
        children: [
          {
            path: '/market/quotes',
            name: 'submittedQuotes',
            meta: {
              title: 'Quotes',
              type: 'Quotes Submitted Quotes page',
              loginRestricted: true,
            },
            component: QuoteSubmittedQuotes,
          },
          {
            path: '/market/quotes/drafts',
            name: 'drafts',
            meta: {
              title: 'Quotes Drafts',
              type: 'Quotes Drafts page',
              loginRestricted: true,
            },
            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
              const authenticationStore = useAuthenticationStore();
              if (!authenticationStore.isReadOnly) {
                next();
              } else {
                next({ name: 'search' });
              }
            },
            component: QuoteDrafts,
          },
        ],
      },
      {
        path: '/market/quotes/:quotecode',
        name: 'quote',
        components: {
          marketRoute: QuotesLayout,
          subContentRoute: QuoteDetailsLayout,
        },
        meta: {
          title: 'Quote',
          type: 'Quote Detail Page',
          loginRestricted: true,
          betaRestricted: 'quotes',
          isDetailPage: true,
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return !authenticationStore.readOnlyAccount;
          },
        },
        beforeEnter: quotesBeforeEnterFeatureCheck,
      },
      {
        path: '/market/quotes/drafts/:quotecode',
        name: 'draft',
        components: {
          marketRoute: QuotesLayout,
          subContentRoute: ListLayout,
        },
        meta: {
          title: 'Quote Draft',
          type: 'Quote Draft Detail Page',
          loginRestricted: true,
          betaRestricted: 'quotes',
          isDetailPage: true,
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return !authenticationStore.readOnlyAccount;
          },
        },
      },
      {
        path: '/market/quotes/:quotecode/checkout/',
        components: {
          marketRoute: CartLayout,
        },
        meta: {
          loginRestricted: true,
          type: 'Checkout page',
        },
        children: [
          {
            name: 'quoteCheckout',
            path: '',
            meta: {
              title: 'Checkout',
              type: 'Checkout page',
              loginRestricted: true,
              betaRestricted: 'quotes',
              accessRestricted: () => {
                const authenticationStore = useAuthenticationStore();
                return !authenticationStore.readOnlyAccount;
              },
            },
            component: CheckoutLayout,
          },
          {
            path: 'receipt/',
            name: 'quoteReceipt',
            meta: {
              title: 'Receipt',
              type: 'Confirmation page',
              loginRestricted: true,
              betaRestricted: 'quotes',
              accessRestricted: () => {
                const authenticationStore = useAuthenticationStore();
                return !authenticationStore.readOnlyAccount;
              },
            },
            component: ReceiptLayout,
            props: true,
          },
        ],
      },
      {
        name: 'quoteproduct',
        path: '/market/quotes/:quotecode/:manufacturer/:manufacturerAid',
        components: {
          marketRoute: QuotesLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          betaRestricted: 'quotes',
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return !authenticationStore.readOnlyAccount;
          },
          isDetailPage: true,
        },
      },
      {
        name: 'quoteoffer',
        path: '/market/quotes/:quotecode/:manufacturer/:manufacturerAid/:offerId',
        components: {
          marketRoute: QuotesLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          betaRestricted: 'quotes',
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return !authenticationStore.readOnlyAccount;
          },
          type: 'Quote page',
          title: 'Quote',
          isDetailPage: true,
        },
      },
      {
        path: '/market/cart',
        redirect: { name: 'cart-list' },
        components: {
          marketRoute: CartLayout,
        },
        name: 'cart',
        meta: {
          loginRestricted: true,
        },
        children: [
          {
            path: 'checkout',
            component: CheckoutLayout,
            children: [
              {
                path: '',
                name: 'checkout',
                meta: {
                  title: 'Checkout',
                  type: 'Checkout page',
                  loginRestricted: true,
                },
                component: MarketPortalLayout,
                children: [
                  {
                    path: 'seller/:sellerId',
                    name: 'checkout-seller',
                    components: {
                      subContentRoute: SellerDetailsLayout,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'checkout/receipt',
            name: 'receipt',
            meta: {
              title: 'Receipt',
              type: 'Confirmation page',
              loginRestricted: true,
            },
            component: ReceiptLayout,
            props: true,
          },
          {
            path: '/market/cart',
            name: 'cart-list',
            component: CartList,
            meta: {
              title: 'Cart',
              type: 'Cart page',
              loginRestricted: true,
            },
          },
        ],
      },
      {
        path: '/market/cart/checkout/receipt/product/:manufacturer/:manufacturerAid',
        components: {
          marketRoute: CartLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
        },
        children: [
          {
            path: '',
            component: ReceiptLayout,
          },
        ],
      },
      {
        path: '/market/cart/checkout/receipt/product/:manufacturer/:manufacturerAid/:offerId',
        components: {
          marketRoute: CartLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
        },
        children: [
          {
            path: '',
            component: ReceiptLayout,
          },
        ],
      },
      {
        path: '/market/cart/product/:manufacturer/:manufacturerAid',
        components: {
          marketRoute: CartLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
          title: 'Cart',
          type: 'Cart page',
        },

        children: [
          {
            name: 'cart-product',
            path: '',
            component: CartList,
          },
        ],
      },
      {
        path: '/market/cart/product/:manufacturer/:manufacturerAid/:offerId',
        components: {
          marketRoute: CartLayout,
          subContentRoute: ProductDetailsLayout,
        },
        meta: {
          loginRestricted: true,
        },
        children: [
          {
            path: '',
            component: CartList,
            name: 'cart-offer',
            meta: {
              title: 'Cart',
              type: 'Cart page',
            },
          },
        ],
      },
      {
        path: '/market/productnotfound',
        name: 'productnotfound',
        components: {
          marketRoute: NotFoundLayout,
        },
        meta: {
          title: 'Product not found',
          type: 'Page not found',
        },
      },
      {
        path: '/market/restricted',
        name: 'restricted',
        components: {
          marketRoute: RestrictedLayout,
        },
        meta: {
          title: '403 Forbidden',
          type: 'Error page',
        },
      },
      {
        path: '/market/styleguide',
        name: 'styleguide',
        components: {
          marketRoute: StyleguideLayout,
        },
      },
      {
        path: '/market/serviceunavailable',
        name: 'serviceunavailable',
        components: {
          marketRoute: ServiceUnavailableLayout,
        },
        meta: {
          title: 'Service Unavailable',
          type: 'Service Unavailable Page',
        },
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
          DetectSiteChange(to, from, () => {
            const authenticationStore = useAuthenticationStore();
            const modalStore = useModalStore();
            next();
            // FORCE logout
            authenticationStore.doLogout(true).then(() => {
              modalStore.closeModal();
            });
          });
        },
      },
      {
        path: '/market/:pathMatch(.*)*',
        name: 'notfound',
        components: {
          marketRoute: NotFoundLayout,
        },
        meta: {
          title: 'Page not found',
          type: 'Page not found',
          forceTrack: {
            pageName: '404:' + window.location,
            errorPage: 'errorPage',
            errorMessage: 'not found',
            siteSection1: 'error',
            siteSection2: 'error',
            siteSection3: 'error',
          },
        },
      },
    ],
  },
];

export const isCheckoutPage = ($route: RouteLocationNormalized) => {
  const routeName = $route?.name;
  if (!routeName) {
    return false;
  }
  // TODO: Fix typing issue
  const checkoutRouteNames = new Set(['checkout', 'quoteCheckout', 'checkout-seller']);

  return checkoutRouteNames.has(routeName);
};

export default marketRoutes;

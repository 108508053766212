import { HybrisService } from '@/src/core/services/hybris-service';
import { IOrderListQuery } from '@/src/core/types/interfaces';
import {
  DisplayDateFormat,
  getEndOfLastMonth,
  getStartOfCurrentMonth,
  getStartOfCurrentYear,
  getStartOfLastMonth,
  InputDateFormat,
  InputDateFormatWithoutZone,
  toHybrisFormat,
} from '@/src/core/utils/dates';
import dayjs from 'dayjs';

export const ORDER_FACET_LIST = [
  'purchaseOrderNumber',
  'code',
  'manufacturerAID',
  'masterShopName',
  'priority',
  'status',
  'orderType',
  'acReg',
] as const;

export const ORDER_SEARCH_FACET_LIST = [
  'purchaseOrderNumber',
  'code',
  'manufacturerAID',
  'masterShopName',
] as const;

export const REPAIR_EXCHANGE_ORDER_SEARCH_FACET_LIST = [
  'purchaseOrderNumber',
  'airbusSapOrderId',
  'orderNumber',
  'cageCode',
  'airCraftRegistrationNumber',
  'partNumber',
] as const;

export const LEASE_ORDER_SEARCH_FACET_LIST = [
  'purchaseOrderNumber',
  'orderNumber',
  'cageCode',
  'airCraftRegistrationNumber',
  'partNumber',
] as const;

export const ORDER_DATE_FILTER_LIST = ['timePeriod', 'fromDate', 'toDate'] as const;

export type OrderFacet = (typeof ORDER_FACET_LIST)[number];
export type OrderFacetMap = Record<OrderFacet, string | undefined>;

export const RECENT_ORDERS_SIZE = 5;

export class OrdersService {
  public static facetMapFromFilters(filters: IOrderListQuery) {
    return ORDER_FACET_LIST.reduce((facetMap, facetKey) => {
      facetMap[facetKey] = filters[facetKey];
      return facetMap;
    }, {} as OrderFacetMap);
  }

  public static buildFacetQuery(facetMap: OrderFacetMap) {
    const search = '';
    const sort = 'date-desc';

    return HybrisService.buildFacetQuery(search, sort, facetMap);
  }

  /**
   * Will try to extract from date in hybris format.
   * @param  {IOrderListQuery} filters
   */
  public static getFromDate(filters: IOrderListQuery): string | undefined {
    const { timePeriod } = filters;

    switch (timePeriod) {
      case 'current-month':
        return toHybrisFormat(getStartOfCurrentMonth());
      case 'last-month':
        return toHybrisFormat(getStartOfLastMonth());
      case 'current-year':
        return toHybrisFormat(getStartOfCurrentYear());
    }

    return undefined;
  }

  /**
   * Will try to extract to date in hybris format.
   * @param  {IOrderListQuery} filters
   */
  public static getToDate(filters: IOrderListQuery): string | undefined {
    const { timePeriod } = filters;

    switch (timePeriod) {
      case 'current-month':
        return toHybrisFormat(dayjs());
      case 'last-month':
        return toHybrisFormat(getEndOfLastMonth());
      case 'current-year':
        return toHybrisFormat(dayjs());
    }

    return undefined;
  }

  /**
   * Will try to extract the beginning of the from date and
   * the ending of the to date in hybris format with timezone
   * @param  {IOrderListQuery} filters
   */
  public static extractDatesWithTimezone(
    filters: IOrderListQuery,
  ): Pick<IOrderListQuery, 'fromDate' | 'toDate'> {
    const { fromDate, toDate } = filters ?? {};
    const result: ReturnType<typeof OrdersService.extractDatesWithTimezone> = {};

    if (fromDate) {
      const startOfDay = dayjs(fromDate, DisplayDateFormat)
        .startOf('day')
        .format(InputDateFormat) as typeof InputDateFormat;
      result.fromDate = OrdersService.offsetLocalTimezone(startOfDay);
    }
    if (toDate) {
      const endOfDay = dayjs(toDate, DisplayDateFormat)
        .endOf('day')
        .format(InputDateFormat) as typeof InputDateFormat;
      result.toDate = OrdersService.offsetLocalTimezone(endOfDay);
    }

    return result;
  }

  /**
   * Will get the local timezone and offset it
   * @param  {typeof InputDateFormat} dateStr
   */
  public static offsetLocalTimezone(dateStr: typeof InputDateFormat) {
    const timeZoneOffsetMinutes = new Date().getTimezoneOffset();

    return (
      dayjs(dateStr, InputDateFormat)
        .add(timeZoneOffsetMinutes, 'minutes')
        .format(InputDateFormatWithoutZone) + 'Z'
    );
  }

  public static isMarketplaceOrder(masterShopId: string) {
    return masterShopId.toLowerCase() !== 'airbus' && masterShopId.toLowerCase() !== 'satair';
  }

  public static isAirbusOrder(masterShopId: string) {
    return masterShopId.toLocaleLowerCase() === 'airbus';
  }
}
